import { useDispatch } from 'react-redux';
import { useClinicsCreateMutation } from 'store/api/clinics/clinicApi';
import { Button, Input, SelectInput } from 'components';
import * as S from './styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { ChangeEvent } from 'react';
import { setMessage } from 'store/slices/message';
import { DrawerMode } from 'types/DrawerMode';
import { showDrawer } from 'store/slices/drawerSlice';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { StatesUSA } from 'constants/statesUSA';
import ReactInputMask from 'react-input-mask';
import { emailValidator, phoneValidator } from 'constants/constants';

type FormValues = {
  clinicName: string;
  state: string;
  city: string;
  street: string;
  zipcode: string;
  phone: string;
  email: string;
  prefix: string;
};

const Shape = yup.object().shape({
  clinicName: yup.string().required('Is required'),
  phone: phoneValidator,
  email: emailValidator.required('Is required'),
  state: yup.string().required('Is required'),
  city: yup.string().required('Is required'),
  zipcode: yup.string().required('Is required'),
  street: yup.string().required('Is required'),
  prefix: yup.string().min(2, 'Must be at least 2 characters').required('Is required'),
});

const AddClinic = () => {
  const dispatch = useDispatch();
  const [createClinic] = useClinicsCreateMutation({});

  const {
    values,
    resetForm,
    handleChange,
    handleSubmit,
    errors,
    setFieldValue,
    setValues,
    setFieldError,
  } = useFormik<FormValues>({
    initialValues: {
      clinicName: '',
      state: '',
      city: '',
      prefix: '',
      street: '',
      zipcode: '',
      phone: '',
      email: '',
    },
    validateOnChange: false,
    validationSchema: Shape,
    onSubmit: () => {
      const data = {
        name: values.clinicName,
        email: values.email,
        state: values.state,
        city: values.city,
        street: values.street,
        zipCode: values.zipcode,
        defaultPrefix: values.prefix,
        phone: [values.phone],
      };

      createClinic({
        ...data,
      })
        .unwrap()
        .then(res => {
          dispatch(showDrawer({ show: false, mode: DrawerMode.DEFAULT, props: null }));
          dispatch(
            setMessage({
              message: 'Clinic was successfully created',
              type: 'success',
            }),
          );
          resetForm();
        })
        .catch(error => {
          dispatch(showDrawer({ show: false, mode: DrawerMode.DEFAULT, props: null }));
          dispatch(setMessage({ message: error.data.message, type: 'error' }));
        });
    },
  });

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFieldError(e.target.id, '');
    handleChange(e);
  };

  return (
    <form
      autoComplete="off"
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <S.Wrapper>
        <S.Content>
          <S.ArticleWrap>
            <S.Article>Add Clinic</S.Article>
          </S.ArticleWrap>
          <S.PaymentInformation>
            <S.InputRow>
              <Input
                id="clinicName"
                name="clinicName"
                label="Clinic name"
                isRequired
                value={values.clinicName}
                onChange={handleChangeInput}
                error={!!errors.clinicName}
                helperText={errors.clinicName}
              />
            </S.InputRow>
            <S.InputRow>
              <Input
                id="prefix"
                name="prefix"
                label="Prefix"
                placeholder="Minimum 2 characters "
                isRequired
                value={values.prefix}
                onChange={handleChangeInput}
                error={!!errors.prefix}
                helperText={errors.prefix}
              />
            </S.InputRow>
            <S.InputRow>
              <ReactInputMask
                mask="+19999999999"
                value={values.phone}
                onChange={handleChangeInput}
                disabled={false}
              >
                <Input
                  label="Phone "
                  id="phone"
                  isRequired
                  name="phone"
                  error={!!errors.phone}
                  helperText={errors.phone}
                />
              </ReactInputMask>

              <Input
                id="email"
                name="email"
                label="Email"
                isRequired
                value={values.email}
                onChange={handleChangeInput}
                error={!!errors.email}
                helperText={errors.email}
              />
            </S.InputRow>
            <S.SubArticle>Address</S.SubArticle>
            <S.InputRow>
              <SelectInput
                label="State"
                isRequired
                id="state"
                name="state"
                value={values.state}
                error={!!errors.state}
                helperText={errors.state}
                onChange={(e: SelectChangeEvent<unknown>) => {
                  setFieldError('state', '');
                  handleChange(e);
                }}
              >
                {StatesUSA.map(state => (
                  <MenuItem key={state.abbreviation} value={state.abbreviation}>
                    <S.MenuItemContent>{state.abbreviation}</S.MenuItemContent>
                  </MenuItem>
                ))}
              </SelectInput>
              <Input
                id="city"
                name="city"
                label="City"
                isRequired
                value={values.city}
                onChange={handleChangeInput}
                error={!!errors.city}
                helperText={errors.city}
              />
            </S.InputRow>
            <S.InputRow>
              <Input
                id="zipcode"
                name="zipcode"
                label="Zipcode"
                isRequired
                value={values.zipcode}
                onChange={handleChangeInput}
                error={!!errors.zipcode}
                helperText={errors.zipcode}
              />
              <Input
                id="street"
                name="street"
                label="Street"
                isRequired
                value={values.street}
                onChange={handleChangeInput}
                error={!!errors.street}
                helperText={errors.street}
              />
            </S.InputRow>
          </S.PaymentInformation>
        </S.Content>
        <S.Footer>
          <S.BtnWrapper>
            <Button text="Create" type="submit" />
          </S.BtnWrapper>
        </S.Footer>
      </S.Wrapper>
    </form>
  );
};

export default AddClinic;
