import React, { useState } from 'react';
import { useDebounce } from 'use-debounce';
import { useGetAllReceptionistsQuery } from 'store/api/staff/staffApi';
import AutoCompleteInput from 'components/atoms/AutoComplete';
import AutoCompleteAllReceptonists from '../../../../../../../../components/atoms/AutoComplete/AutoCompleteAllReceptonists';

type Props = {
  selectedValue: any;
  setSelectedValue: (selectedValue: any) => void;
  getReceptionistsList?: any;
  error?: boolean;
};

export const GetReceptionistList = ({
  selectedValue,
  setSelectedValue,
  getReceptionistsList,
  error,
}: Props) => {
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 500);

  const getAll = useGetAllReceptionistsQuery({
    q: debouncedSearchValue || undefined,
    take: 100,
    page: 1,
  });

  return (
    <AutoCompleteAllReceptonists
      data={getAll?.currentData?.rows || []}
      mainLabel={'Receptionists'}
      loading={getAll.isLoading}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      selectedValue={selectedValue}
      setSelectedValue={setSelectedValue}
      getReceptionistsList={getReceptionistsList}
      isRequired
      error={error}
    />
  );
};
