import React, { memo, useEffect } from 'react';
import { useFormik } from 'formik';
import { ClaimValues, ProviderInfoFormType } from 'types/AppointmentNewTypes';
import { RenderingCard } from './RenderingCard';
import { ServiceCard } from './ServiceCard';
import { BillingCard } from './BillingCard';

import * as S from '../../styles';
import {
  providerClaimForm,
  providerClaimSchema,
} from 'pages/Billing/components/Claims/formikClaim';
import { ClaimActionEnum } from '../../ClaimForm';
import { useAllFacilitiesGetQuery } from '../../../../../../../../store/api/facilities/facilityApi';

type Props = {
  data?: ClaimValues;
  getData: (value: any) => void;
  isSave: ClaimActionEnum | null;
  setIsSave: (value: ClaimActionEnum | null) => void;
  setIsDirty: (isDirty: boolean) => void;
  setRenderingProviderId: (renderingProviderId?: string | null) => void;
  setBillingEin: (billingEin?: string | null) => void;
  checkStatusCondition?: boolean;
  isBlankClaim?: boolean;
};

export const ProviderBlock = memo(
  ({
    checkStatusCondition,
    data,
    getData,
    isSave,
    setIsSave,
    setIsDirty,
    setRenderingProviderId,
    setBillingEin,
    isBlankClaim,
  }: Props) => {
    const providerFormik = useFormik<Partial<ProviderInfoFormType>>({
      initialValues: providerClaimForm(data),
      validateOnChange: false,
      enableReinitialize: true,
      validationSchema: providerClaimSchema,
      onSubmit: values => {
        console.log(values);
      },
    });
    const facilities = useAllFacilitiesGetQuery({ page: 1, take: 1000 });

    useEffect(() => {
      if (isSave) {
        (async () => {
          const errors = await providerFormik.validateForm();
          if (!Object.keys(errors).length) {
            getData(providerFormik.values);
          } else setIsSave(null);
        })();
      }
    }, [isSave]);

    useEffect(() => {
      if (providerFormik.dirty) {
        setIsDirty(true);
      }
    }, [providerFormik.dirty]);

    useEffect(() => {
      setRenderingProviderId(providerFormik.values?.renderingNpi);
    }, [providerFormik.values.renderingNpi]);

    return (
      <S.BlockWrapper>
        <S.CardWrapper>
          <RenderingCard
            providerFormik={providerFormik}
            checkStatusCondition={checkStatusCondition}
            isBlankClaim={isBlankClaim}
            clinicId={data?.clinicId}
            facilities={facilities?.currentData?.rows}
          />
          <ServiceCard
            providerFormik={providerFormik}
            checkStatusCondition={checkStatusCondition}
            isBlankClaim={isBlankClaim}
          />
          <BillingCard
            providerFormik={providerFormik}
            setBillingEin={setBillingEin}
            checkStatusCondition={checkStatusCondition}
            isBlankClaim={isBlankClaim}
            facilities={facilities?.currentData?.rows}
          />
        </S.CardWrapper>
      </S.BlockWrapper>
    );
  },
);
