import { useState } from 'react';
import { AddPatientsSVG } from 'assets/icons';
import { Button, CustomPagination, CustomTable, Dialog, SearchInput } from 'components';
import * as S from '../../styles';
import { useStaffGetQuery } from 'store/api/clinics/clinicApi';
import { useDebounce } from 'use-debounce';
import { useAppSelector } from 'hooks/useAppSelector';
import StaffLocalRow from './TableData/StaffLocalRow';
import { Roles } from 'types/Roles';
import { useParams } from 'react-router-dom';
import AssignClinicDialog from 'pages/SuperAdmin/components/ProfileStaff/components/AssignTo/components/AssignClinicDialog';
import { showDrawer } from 'store/slices/drawerSlice';
import { DrawerMode } from 'types/DrawerMode';
import { useAppDispatch } from 'hooks/useAppDispatch';

const headers = [
  { name: 'Doctor name', key: 'DoctorName' },
  { name: 'Clinic name', key: 'ClinicName' },
  { name: 'Phone', key: 'Phone' },
  { name: 'Email', key: 'Email' },
  { name: 'Status', key: 'Status' },
];

type Props = {
  fromClinic?: boolean;
};

const LOCAL_ADMINS_LIMIT = 20;

const StaffLocal = ({ fromClinic = true }: Props) => {
  const selectedClinic = useAppSelector(state => state.selectedClinic);
  const dispatch = useAppDispatch();

  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 500);
  const [page, setPage] = useState(1);
  const params = useParams();
  const [openDialog, setOpenDialog] = useState(false);

  const getList = useStaffGetQuery({
    position: Roles.LOCATION_MANAGER,
    id: params.clinicId || selectedClinic.id,
    page: page,
    take: 20,
    // q: debouncedSearchValue,
  });

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPage(1);
    setSearchValue(e.target.value);
  };

  return (
    <S.TableBody>
      <Dialog open={openDialog} onClose={() => setOpenDialog(!openDialog)}>
        <AssignClinicDialog
          onClose={() => setOpenDialog(!openDialog)}
          role={Roles.LOCATION_MANAGER}
          clinicId={params.clinicId}
          getReceptionistsList={getList.currentData?.rows}
        />
      </Dialog>
      <S.HeaderTable>
        <CustomPagination
          isFetching={getList.isFetching}
          setPage={setPage}
          currentPage={getList.currentData?.page}
          lastPage={getList.currentData?.lastPage}
          count={getList?.currentData?.count}
          text={'local admins'}
          limit={LOCAL_ADMINS_LIMIT}
        />
        <S.SubtitleItems>
          <S.SearchWrapper>
            <SearchInput value={searchValue} onChange={handleSearch} />
          </S.SearchWrapper>
          <S.ButtonWrapper>
            {fromClinic ? (
              <Button
                disabled={
                  getList?.currentData?.rows && getList?.currentData?.rows?.length >= 1
                }
                text={'Assign Local Admin'}
                onClick={() => {
                  setOpenDialog(!openDialog);
                }}
              >
                <AddPatientsSVG />
              </Button>
            ) : (
              <Button
                text="Add Local Admin"
                disabled={
                  getList?.currentData?.rows && getList?.currentData?.rows?.length >= 1
                }
                onClick={() =>
                  dispatch(
                    showDrawer({
                      mode: DrawerMode.ADD_LOCAL,
                      props: null,
                      show: true,
                    }),
                  )
                }
              >
                <AddPatientsSVG />
              </Button>
            )}
          </S.ButtonWrapper>
        </S.SubtitleItems>
      </S.HeaderTable>
      <CustomTable headers={headers} isFetching={getList.isFetching}>
        <StaffLocalRow clickable data={getList?.currentData?.rows} headers={headers} />
      </CustomTable>
    </S.TableBody>
  );
};

export default StaffLocal;
