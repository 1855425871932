import { useDispatch } from 'react-redux';
import * as S from './styles';
import { Button, Input } from '../../../../../../components';
import { ChangeEvent, useEffect, useState } from 'react';
import { IconButton, InputAdornment } from '@mui/material';
import { EyeVisibleSVG } from '../../../../../../assets/icons';
import { useCashboxUpdateMutation } from '../../../../../../store/api/cash/cashboxApi';
import { setMessage } from '../../../../../../store/slices/message';
import InputNumber from '../../../../../../components/atoms/Input/InputNumber';

type Props = {
  onClose: () => void;
  data?: any;
};

const CashBoxDialog: React.FC<Props> = ({ onClose, data }) => {
  const dispatch = useDispatch();

  const [updateCashBox] = useCashboxUpdateMutation({});

  const handleCreate = async () => {
    updateCashBox({
      id: data.id,
      out: out.length > 0 ? Number(out) : 0,
      note: note,
      date: data.date,
    })
      .unwrap()
      .then(res => {
        onClose();
        dispatch(
          setMessage({
            message: res.message,
            type: 'success',
          }),
        );
      })
      .catch(error => {
        onClose();
        dispatch(
          setMessage({
            message: error.data.message,
            type: 'error',
          }),
        );
      });
  };

  const [out, setOut] = useState('');
  const [note, setNote] = useState('');

  useEffect(() => {
    data?.out !== null && setOut(data?.out);
    data?.note !== null && setNote(data?.note);
  }, []);

  return (
    <S.Content>
      <S.Article>Edit Cash Report </S.Article>
      <S.InputWrapper>
        <InputNumber
          type="number"
          label="Out"
          isSum={'USD'}
          value={out}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setOut(e.target.value)}
        />
      </S.InputWrapper>
      <Input
        multiline
        rows={4}
        label="Note"
        value={note}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setNote(e.target.value)}
      />
      <S.ButtonWrapper>
        <Button text="Update" onClick={handleCreate} />
      </S.ButtonWrapper>
    </S.Content>
  );
};

export default CashBoxDialog;
