import { Dialog, Tab } from '@mui/material';
import { Button, TabsItem } from 'components';
import * as S from './styles';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ClaimHistoryMessages from './components/ClaimHistoryMessages';
import ClaimHistoryEvents from './components/ClaimHistoryEvents';
import { DeleteRedBtnSVG, DownloadFileSVG, JPGSVG, PDFSVG } from 'assets/icons';
import { useDeleteFileMutation } from 'store/api/files/files';
import ConfirmDeleteDialog from 'components/molecules/ConfirmDeleteDialog';
import {
  useLazyAttachmentsGetQuery,
  useUploadAttachmentsMutation,
} from 'store/api/claims/claimsApi';
import { ClaimStatusType } from 'types/InvoiceType';
import { setMessage } from 'store/slices/message';
import { useAppDispatch } from 'hooks/useAppDispatch';
import CircularLoader from 'components/atoms/CircuralLoader';
import { useNavigate } from 'react-router-dom';
import { setTab } from '../../../../../../store/slices/claimTabSlice';
import ClaimErrorDialog from '../../../../../../components/molecules/Dialogs/ClaimErrorDialog';

type Props = {
  isShow: boolean;
  claimId: string;
  claimStatus?: string;
  disable?: boolean;
  setDisableAttachments: (value: boolean) => void;
  exposeModalControls?: (openModal: () => void, closeModal: () => void) => void;
};

const ClaimHistory = ({
  isShow,
  claimId,
  claimStatus,
  disable,
  setDisableAttachments,
  exposeModalControls,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();

  const [uploadInitialFiles, uploadInitialFilesStatus] = useUploadAttachmentsMutation({});
  const [deleteFile, statusDelete] = useDeleteFileMutation({});
  const [getAttachments, attachmentsStatus] = useLazyAttachmentsGetQuery({});
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(0);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [disableForm, setDisableForm] = useState(false);
  const [showUpdateConfirmDialog, setShowUpdateConfirmDialog] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [initialAttachments, setInitialAttachments] = useState<any[]>([]);

  const handleClick = () => {
    setShowUpdateConfirmDialog(true);
  };

  const handleInputClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const { target = {} } = event || {};

    // @ts-ignore
    target.value = '';
  };

  const handleClearImg = (id: string) => {
    deleteFile({ id })
      .unwrap()
      .then(() => {
        if (claimStatus === ClaimStatusType.ERROR) {
          dispatch(setTab(3));
          navigate('/claims');
        }
      });
  };

  const handleSuccessDelete = () => {
    showConfirmDialog && attachmentsStatus.data?.forEach(i => deleteFile({ id: i.id }));
  };
  const handleSuccessUpdate = () => {
    if (disabled) {
      return;
    }

    inputRef?.current?.click();
  };

  const handleChangeFile = (e: any) => {
    const fileList = e.target.files;
    if (fileList) {
      if (fileList.length > 1) {
        return dispatch(
          setMessage({
            message: 'You can send only 1 file',
            type: 'warning',
          }),
        );
      }

      const file = fileList[0];
      if (file.size > 60000000) {
        return dispatch(
          setMessage({
            message: 'File must be less than 60 MB',
            type: 'warning',
          }),
        );
      }

      uploadInitialFiles({ files: [file], claimId })
        .unwrap()
        .then(() => {
          if (claimStatus === ClaimStatusType.ERROR) {
            dispatch(setTab(3));
            navigate('/claims');
          }
          dispatch(
            setMessage({ message: 'File uploaded successfully!', type: 'success' }),
          );
          getAttachments({ claimId });
        })
        .catch(() => {
          dispatch(setTab(2));
          navigate('/claims');
          dispatch(
            setMessage({
              message: 'Failed to upload the file, the claim get status error',
              type: 'error',
            }),
          );
        });
    }
  };

  const isShowAttachments = useMemo(() => {
    return (
      claimStatus === ClaimStatusType.RELEASED || claimStatus === ClaimStatusType.ERROR
      // claimStatus === ClaimStatusType.MODIFIED
    );
  }, [claimStatus]);

  const disabled = useMemo(() => {
    return (
      uploadInitialFilesStatus.isLoading ||
      statusDelete.isLoading ||
      attachmentsStatus.isFetching
    );
  }, [
    uploadInitialFilesStatus.isLoading,
    statusDelete.isLoading,
    attachmentsStatus.isFetching,
  ]);

  useEffect(() => {
    if (isShow) {
      getAttachments({ claimId })
        .unwrap()
        .then(data => {
          setInitialAttachments(data);
        });
    }
  }, [isShow]);

  useEffect(() => {
    if (claimStatus === ClaimStatusType.ERROR) {
      getAttachments({ claimId })
        .unwrap()
        .then(data => {
          const disableForm = data.some(t => t.isIssue);
          setDisableAttachments(disableForm);
          if (disableForm) {
            setDisableForm(true);
          }
        });
    }
    return () => (setDisableForm(false), setDisableAttachments(false));
  }, [claimStatus]);

  useEffect(() => {
    if (claimStatus === ClaimStatusType.ERROR && disableForm) {
      setIsErrorModalOpen(true);
    }

    return () => {
      setIsErrorModalOpen(false);
    };
  }, [claimStatus, disableForm]);

  useEffect(() => {
    if (exposeModalControls && claimStatus === ClaimStatusType.ERROR && disableForm) {
      exposeModalControls(
        () => setIsErrorModalOpen(true),
        () => setIsErrorModalOpen(false),
      );
    }
  }, [exposeModalControls]);

  return (
    <S.Wrapper $isShow={isShow}>
      {isShow && (
        <S.Content>
          <S.Box>
            <S.Header>
              <TabsItem tabIndex={tabIndex} setTabIndex={setTabIndex}>
                <Tab disableRipple label="Messages"></Tab>
                <Tab disableRipple label="Events"></Tab>
                {/*<Tab disableRipple label="Related"></Tab>*/}
              </TabsItem>
            </S.Header>
            <S.Body className={isShowAttachments ? 'small' : ''}>
              {tabIndex === 0 && <ClaimHistoryMessages claimId={claimId} />}
              {tabIndex === 1 && <ClaimHistoryEvents claimId={claimId} />}
            </S.Body>
          </S.Box>

          {isShowAttachments && (
            <S.Footer>
              <S.TitleBox>
                <S.Title>Attachments</S.Title>
                {disabled && (
                  <S.Loader>
                    <CircularLoader color="#0084B1" size={16} />
                  </S.Loader>
                )}
                {/*{!!attachmentsStatus.data?.length && (*/}
                {/*  <DeleteGrayBtnSVG*/}
                {/*    style={{ cursor: 'pointer' }}*/}
                {/*    onClick={() => setShowConfirmDialog(true)}*/}
                {/*  />*/}
                {/*)}*/}
              </S.TitleBox>
              <S.Text>
                Downloads are available in file sizes up to 60 MB and formats: JPG, TIF,
                TIFF, PDF, JPEG and PNG
              </S.Text>
              <label htmlFor="file">
                <input
                  ref={inputRef}
                  type="file"
                  id="file"
                  onChange={handleChangeFile}
                  accept="image/png, image/jpeg, image/jpg, image/tiff, application/pdf"
                  hidden
                  onClick={handleInputClick}
                />
              </label>
              <S.UploadButtonWrapper>
                <Button
                  text=""
                  disabled={disable || claimStatus !== ClaimStatusType.RELEASED}
                  onClick={handleClick}
                  customStyle={{
                    width: '210px',
                    border: '1px solid #0084b1',
                    backgroundColor: 'transparent',
                  }}
                >
                  <S.ButtonArticle>
                    <DownloadFileSVG /> Upload Attachments
                  </S.ButtonArticle>
                </Button>
                {attachmentsStatus?.data?.map(item => (
                  <S.FileRow
                    key={item?.id}
                    onClick={() => {
                      window.open(item?.url);
                    }}
                    style={{
                      backgroundColor: item?.isIssue ? '#C6606033' : 'transparent',
                      color: item?.isIssue ? '#C66060' : '',
                    }}
                  >
                    <S.InfoRow>
                      {item?.name.includes('.pdf') ? (
                        <PDFSVG className="icon" />
                      ) : (
                        <JPGSVG className="icon" />
                      )}
                      {item?.name}
                    </S.InfoRow>
                    {item?.isIssue && (
                      <DeleteRedBtnSVG
                        style={{ cursor: 'pointer' }}
                        onClick={e => {
                          if (disabled) {
                            return;
                          }

                          e.stopPropagation();
                          handleClearImg(item?.id);
                        }}
                      />
                    )}
                  </S.FileRow>
                ))}
              </S.UploadButtonWrapper>
            </S.Footer>
          )}
        </S.Content>
      )}

      <Dialog open={isErrorModalOpen} onClose={() => setIsErrorModalOpen(false)}>
        <ClaimErrorDialog
          onClose={() => setIsErrorModalOpen(false)}
          fullTitle="Upload attachment error"
          helperText={
            <>
              This Claim has been moved to this tab because there is data that does not
              meet the requirements of the Clearing House. Please check the{' '}
              <strong>Show Claim History</strong> section of the{' '}
              <strong>Historical Errors</strong> subsection to understand what needs to be
              corrected.
              <br />
              <br />
              <strong>Important:</strong> If the fields of Claims are not available for
              changes, you need to delete the attachment with the error. After deletion,
              the Claim will automatically return to the Released tab. The attachment will
              need to be corrected and attached again.
            </>
          }
        />
      </Dialog>
      <Dialog
        open={showUpdateConfirmDialog}
        onClose={() => setShowUpdateConfirmDialog(false)}
      >
        <ConfirmDeleteDialog
          onClose={() => setShowUpdateConfirmDialog(false)}
          titleText="files"
          fullTitle={`Are you sure you want to add this document to Claim #${claimId} ?  `}
          helperText="This action is irreversible and you will not be able to delete anything once submitted."
          onSuccess={handleSuccessUpdate}
        />
      </Dialog>
    </S.Wrapper>
  );
};

export default ClaimHistory;
