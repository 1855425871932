import React, { useEffect, useState } from 'react';
import {
  useGetAllDoctorsQuery,
  useGetAllRenderingProviderQuery,
} from 'store/api/staff/staffApi';
import useInfinityScroll from 'hooks/useInfinityScroll';
import { CustomTable, RadioButton } from 'components';
import { TableCell, TableRow } from '@mui/material';
import SearchInput from 'components/atoms/SearchInput';
import { useDebounce } from 'use-debounce';

import * as S from './styles';
import { useAllFacilitiesGetQuery } from '../../../../../../../../store/api/facilities/facilityApi';

const doctorHeaders = [
  { name: '', key: 'checkbox' },
  { name: 'Name', key: 'name' },
  { name: 'NPI', key: 'npi' },
  { name: 'Claims amount', key: 'claimsAmount' },
];

type Props = {
  selectedItem: string | null;
  setSelectedItem: (selectedItem: string | null) => void;
  setIsLoading: (isLoading: boolean) => void;
  currentValue?: string;
  claimId?: number;
  clinicId?: string | null;
  invoiceItemId?: number | null;
};

export const RenderingProviderTable = ({
  selectedItem,
  setSelectedItem,
  setIsLoading,
  currentValue,
  claimId,
  invoiceItemId,
  clinicId,
}: Props) => {
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 500);
  const [page, setPage] = useState(1);

  const getRenderingProviders = useGetAllRenderingProviderQuery({
    claimId,
    clinicId,
    invoiceItemId,
    q: debouncedSearch,
    page,
    take: 10,
  });
  const { setLastElement } = useInfinityScroll({
    fetchNextData: () => setPage(prevPage => prevPage + 1),
    hasMore:
      (getRenderingProviders.currentData?.page ?? 0) <
      (getRenderingProviders.currentData?.lastPage ?? 0),
  });
  useEffect(() => {
    getRenderingProviders.refetch();
  }, [debouncedSearch, page]);

  useEffect(() => {
    if (getRenderingProviders.isFetching) {
      setIsLoading(true);
    } else setIsLoading(false);
  }, [getRenderingProviders.isFetching]);

  useEffect(() => {
    if (currentValue) {
      setSelectedItem(
        getRenderingProviders.currentData?.rows?.find(
          i => i?.renderingId === currentValue,
        )?.renderingId ?? null,
      );
    }
  }, [getRenderingProviders.currentData]);

  return (
    <>
      <S.SearchWrapper>
        <SearchInput
          isSearch={!!search}
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
      </S.SearchWrapper>
      <S.Table className={'all'}>
        <CustomTable headers={doctorHeaders}>
          {!!getRenderingProviders.currentData?.rows.length &&
            getRenderingProviders.currentData.rows?.map((row, index, { length }) => {
              const refLast = index === length - 1 ? setLastElement : null;

              return (
                <TableRow
                  key={`${row}-${index}`}
                  sx={{
                    '& .MuiTableCell-root': {
                      background: selectedItem === row?.renderingId ? '#e7e8ec' : '',
                    },
                  }}
                  ref={refLast}
                >
                  <TableCell>
                    <RadioButton
                      onClick={() =>
                        selectedItem === row?.renderingId
                          ? setSelectedItem(null)
                          : setSelectedItem(row?.renderingId)
                      }
                      checked={selectedItem === row?.renderingId}
                    />
                  </TableCell>
                  <TableCell>
                    <S.Text>{row?.renderingName ? row?.renderingName : ''}</S.Text>
                  </TableCell>
                  <TableCell>
                    <S.Text>{row?.renderingNpi ?? '-'}</S.Text>
                  </TableCell>
                  <TableCell>
                    <S.Text>{row?.claimsCount ?? '-'}</S.Text>
                  </TableCell>
                </TableRow>
              );
            })}
        </CustomTable>
      </S.Table>
    </>
  );
};
