export const updateFormikValues = (formikValues: any, updates: any) => {
  Object.keys(updates).forEach(key => {
    if (key.includes('.')) {
      const keys = key.split('.');
      let obj = formikValues;
      for (let i = 0; i < keys.length - 1; i++) {
        if (!obj[keys[i]] || typeof obj[keys[i]] !== 'object') {
          obj[keys[i]] = {};
        }
        obj = obj[keys[i]];
      }
      obj[keys[keys.length - 1]] = updates[key];
    } else if (key !== 'firstName' && key !== 'lastName') {
      formikValues[key] = updates[key];
    }
  });
  return formikValues;
};

export const deleteNames = (obj: any) => {
  delete obj?.firstName;
  delete obj?.lastName;
  delete obj?.userId;
  delete obj?.dateOfBirth;
  return obj;
};

export const updateFormikValuesWithKey = (obj: any, name: string, inputValue: any) => {
  const path = name.split('.');
  let current = obj;
  for (let i = 0; i < path.length - 1; i++) {
    const key = path[i];
    if (!current[key]) {
      current[key] = {};
    }
    current = current[key];
  }
  current[path[path.length - 1]] = inputValue;
  return deleteNames(obj);
};

export const removeEmptyFields = (obj: any): any => {
  return Object.fromEntries(
    Object.entries(obj).reduce((acc: [string, any][], [key, value]) => {
      if (value === null || value === undefined || value === '') return acc;
      if (typeof value === 'object') {
        const cleanedValue = removeEmptyFields(value);
        if (Object.keys(cleanedValue).length > 0) {
          acc.push([key, cleanedValue]);
        }
      } else {
        acc.push([key, value]);
      }
      return acc;
    }, []),
  );
};
