import {
  AlertItem,
  Button,
  ConfirmationMailing,
  Dialog,
  Drawer,
  HorizontalHeader,
  LinearLoader,
  Marketing,
  MenuSectionItem,
  VerticalMenu,
} from 'components';
import { useAppSelector } from 'hooks/useAppSelector';
import * as S from './styles';
import { showDrawer } from 'store/slices/drawerSlice';
import { DrawerMode } from 'types/DrawerMode';
import ChooseDrawerContent from 'services/chooseDrawerContent';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { setSelectedClinic } from 'store/slices/selectedClinic';
import {
  AccountingTableSVG,
  AvatarSVG,
  CalendarSVG,
  ChatsSVG,
  ClaimMenuSVG,
  ClinicMenuIconSVG,
  ConfirmationMailingSVG,
  CreateNotificationSVG,
  DashboardSVG,
  FacilitiesMenuIconSVG,
  InsuranceSVG,
  InvoicesSVG,
  MarketingSVG,
  ServicesSVG,
  StaffIconSVG,
  TasksSVG,
  TemplatesSVG,
  TicketSVG,
} from 'assets/icons';
import { useClinicsGetQuery } from 'store/api/clinics/clinicApi';
import Patients from '../Receptionist/components/Patients';
import PatientProfile from '../Receptionist/components/PatientProfile';
import Staff from './components/Staff';
import Profile from '../Receptionist/components/Profile';
import Clinics from './components/Clinics';
import Chats from '../Receptionist/components/Chats';
import Tickets from '../Receptionist/components/Tickets';
import ProfileStaff from './components/ProfileStaff';
import ProfileClinic from './components/ProfileClinic';
import Dashboard from '../Receptionist/components/Dashboard';
import { getChats } from 'hooks/useChat';
import Templates from './components/Templates';
import { useAppDispatch } from 'hooks/useAppDispatch';
import CreateNotificationDialog from 'components/molecules/Dialogs/CreateNotificationDialog';
import { useLastSelectedClinicMutation } from 'store/api/user/profileApi';
import AppointmentsView from 'components/organismus/AppointmentsView';
import InitialVisitFormPage from './components/Templates/components/pages/InitialVisitFormPage';
import PagePTInsurance from './components/Templates/components/pages/PagePhysicalTherapyInsurance';
import PageACAInsurance from './components/Templates/components/pages/PageACAInsurance';
import PagePTInitialConsultation from './components/Templates/components/pages/PagePTInitialConsultation';
import PageClientRegistration from './components/Templates/components/pages/ClientRegistrationPage';
import Insurance from './components/Insurance';
import InsuranceProfile from './components/InsuranceProfile';
import { Services } from './components/Services';
import InvoiceDetail from 'pages/Billing/components/InvoiceDetail';
import InvoicePrepaymentDetail from 'pages/Billing/components/InvoicePrepaymentDetail';
import InvoicePostPaymentDetail from 'pages/Billing/components/InvoicePostPaymentDetail';
import Claims from 'pages/Billing/components/Claims';
import ClaimForm from 'pages/Billing/components/Claims/components/ClaimForm';
import AccountingTable from 'pages/Billing/components/AccountingTable';
import Invoices from 'pages/Billing/components/Invoices';
import { useAuthMeMutation } from 'store/api/auth/authApi';
import { setUser } from 'store/api/auth/authSlice';
import { UserStatus } from 'types/UserStatuses';
import Facilities from './components/Facilities';
import ProfileFacility from './components/ProfileFacility';

const SuperAdmin = () => {
  const drawerState = useAppSelector(state => state.drawer);
  const userInfo = useAppSelector(state => state.auth);
  const dispatch = useAppDispatch();

  const connected = useAppSelector(({ chats }) => chats.connected);
  const chats = useAppSelector(({ chats }) => chats.chats);
  const pendingAppointmentCount = useAppSelector(
    ({ chats }) => chats.pendingAppointmentCount,
  );
  const countMessages = useAppSelector(({ chats }) => chats.chats?.count);
  const [isNoClinic, setIsNoClinic] = useState(false);

  useEffect(() => {
    if (connected) {
      getChats();
    }
  }, [connected]);

  const [clinic, setClinics] = useState('');

  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const navigate = useNavigate();

  const location = useLocation().pathname;

  const clinics = useClinicsGetQuery({});
  const [changeSelectedClinic, status] = useLastSelectedClinicMutation();
  const [authMe] = useAuthMeMutation();

  const { isHover } = useAppSelector(state => state.isHover);

  const routeList = [
    {
      article: 'Appointments',
      route: '/appointments',
      icon: (
        <S.IconWrapper>
          <CalendarSVG />
          {
            <S.AppointmentCircle $isHover={isHover}>
              {pendingAppointmentCount?.pendingAppointmentCount}
            </S.AppointmentCircle>
          }
        </S.IconWrapper>
      ),
      appointmentCount: pendingAppointmentCount?.pendingAppointmentCount,
    },
    { article: 'Patients', route: '/patients', icon: <AvatarSVG /> },
    {
      article: 'Chats',
      route: '/chats',
      icon: (
        <S.IconWrapper>
          <ChatsSVG />
          {<S.ChatsCircle $isHover={isHover}>{countMessages}</S.ChatsCircle>}
        </S.IconWrapper>
      ),
      count: countMessages,
    },
    { article: 'Staff', route: '/staff', icon: <StaffIconSVG /> },
    { article: 'Clinics', route: '/clinics', icon: <ClinicMenuIconSVG /> },
    { article: 'Facilities', route: '/facilities', icon: <FacilitiesMenuIconSVG /> },
    // {
    //   article: "Clinic Info",
    //   route: `/clinics/${selectedClinic.id}`,
    //   icon: <ClinicMenuIconSVG />,
    // },
    { article: 'Tickets', route: '/tickets', icon: <TasksSVG /> },
    { article: 'Dashboard', route: '/dashboard', icon: <DashboardSVG /> },
    { article: 'Marketing', route: '/marketing', icon: <MarketingSVG /> },
    { article: 'Templates', route: '/templates', icon: <TemplatesSVG /> },
    { article: 'Services', route: '/services', icon: <ServicesSVG /> },
    { article: 'Insurance', route: '/insurance', icon: <InsuranceSVG /> },

    //TODO: remove from
    {
      article: 'Accounting table',
      route: '/accountingtable',
      icon: <AccountingTableSVG />,
    },
    { article: 'Claims', route: '/claims', icon: <ClaimMenuSVG /> },
    { article: 'Invoices', route: '/invoices', icon: <InvoicesSVG /> },
    //TODO: remove to
    {
      article: 'Confirmation mailing',
      route: '/confirmation-mailing',
      icon: <ConfirmationMailingSVG />,
    },
  ];

  useEffect(() => {
    setIsNoClinic(false);
    if (!clinic) {
      if (userInfo.selectedClinicId) {
        userInfo && setClinics(userInfo.selectedClinicId);
        userInfo && dispatch(setSelectedClinic({ id: userInfo.selectedClinicId }));
      } else {
        if (clinics.currentData?.rows?.at(0)) {
          (async () => {
            await changeSelectedClinic({
              clinicId: clinics?.currentData?.rows?.at(0)?.id,
            });
            await authMe({})
              .unwrap()
              .then(res => {
                dispatch(setUser(res));
              })
              .catch(error => {
                dispatch(setUser({ status: UserStatus.BUFFER }));
              });
          })();
        } else {
          setIsNoClinic(true);
        }
      }
    }
  }, [clinics?.currentData?.rows?.at(0)?.id]);

  useEffect(() => {
    dispatch(setSelectedClinic({ id: clinic }));
  }, [clinic]);

  const [openDialog, setOpenDialog] = useState(false);

  const handleChangeDialog = () => {
    setOpenDialog(openDialog => !openDialog);
  };
  if (!selectedClinic.id) return null;
  return (
    <>
      <Drawer
        open={drawerState.show}
        onClose={() =>
          dispatch(showDrawer({ show: false, mode: DrawerMode.DEFAULT, props: null }))
        }
      >
        <ChooseDrawerContent />
      </Drawer>
      <Dialog open={openDialog} onClose={handleChangeDialog}>
        <CreateNotificationDialog onClose={handleChangeDialog} />
      </Dialog>

      <S.Wrapper>
        {!selectedClinic?.id && isNoClinic && (
          <S.AlertWrapper>
            <AlertItem
              text={'Please create at least one Clinic to work in the application'}
              type="warning"
            />
          </S.AlertWrapper>
        )}
        <HorizontalHeader isNotify={true} />
        {selectedClinic ? (
          <S.Content $isHover={isHover}>
            <Routes>
              <Route path="patients" element={<Patients />} />
              <Route path="patients/:patientId" element={<PatientProfile />} />
              <Route path="profile" element={<Profile />} />
              <Route path="staff/:profileId" element={<ProfileStaff />} />
              <Route path="clinics" element={<Clinics />} />
              <Route path="clinics/:clinicId" element={<ProfileClinic />} />
              <Route path="facilities" element={<Facilities />} />
              <Route path="facilities/:facilityId" element={<ProfileFacility />} />
              <Route path="chats" element={<Chats />} />
              <Route path="tickets" element={<Tickets />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="marketing" element={<Marketing />} />
              <Route path="templates" element={<Templates />} />
              <Route path="insurance" element={<Insurance />} />
              <Route path="insurance/:insuranceId?" element={<InsuranceProfile />} />
              <Route path="insurance/new" element={<InsuranceProfile />} />
              <Route path="services" element={<Services />} />
              <Route path="confirmation-mailing" element={<ConfirmationMailing />} />
              {/* Routes forms */}
              <Route
                path="templates/initial-visit-form"
                element={<InitialVisitFormPage />}
              />
              <Route
                path="templates/physical-therapy-insurance"
                element={<PagePTInsurance />}
              />
              <Route
                path="templates/client-registration-form"
                element={<PageClientRegistration />}
              />
              <Route
                path="/templates/acupuncture-insurance"
                element={<PageACAInsurance />}
              />
              <Route
                path="/templates/pt-initial-evaluation"
                element={<PagePTInitialConsultation />}
              />
              {/* Routes forms */}
              <Route path="confirmation-mailing" element={<ConfirmationMailing />} />
              <Route path="/appointments" element={<AppointmentsView />} />
              <Route path="/staff" element={<Staff />} />
              {/*TODO: remove from*/}
              <Route path="invoices/*" element={<Invoices />} />
              <Route path="invoices/sheet/:invoiceId" element={<InvoiceDetail />} />
              <Route
                path="invoices/pre-payment/:id"
                element={<InvoicePrepaymentDetail />}
              />
              <Route
                path="invoices/post-payment/:id"
                element={<InvoicePostPaymentDetail />}
              />
              <Route path="claims" element={<Claims />} />
              <Route path="claim-form/:claimId?" element={<ClaimForm />} />
              <Route path="accountingtable" element={<AccountingTable />} />
              {/*TODO: remove to*/}

              <Route path="*" element={<Navigate to="/appointments" />} />
            </Routes>
          </S.Content>
        ) : (
          <LinearLoader />
        )}
        <VerticalMenu>
          <S.Items>
            {routeList.map(route => {
              return (
                <MenuSectionItem
                  key={route.article}
                  text={route.article}
                  onClick={() => navigate(`${route.route}`)}
                  $active={location.includes(`${route.route}`) && true}
                  count={route?.count}
                  appointmentCount={route?.appointmentCount}
                >
                  {route.icon}
                </MenuSectionItem>
              );
            })}
          </S.Items>
          <S.Buttons>
            <S.ButtonWrapper $isHover={isHover}>
              <Button
                text={isHover && window.innerWidth > 450 ? 'Create Notification' : ''}
                backgroundColor="#848A9B"
                onClick={handleChangeDialog}
              >
                <CreateNotificationSVG />
              </Button>
            </S.ButtonWrapper>
            <S.ButtonWrapper $isHover={isHover}>
              <Button
                text={isHover && window.innerWidth > 450 ? 'Make a ticket' : ''}
                onClick={() =>
                  dispatch(
                    showDrawer({
                      show: true,
                      mode: DrawerMode.TICKET_CREATE,
                    }),
                  )
                }
              >
                <TicketSVG />
              </Button>
            </S.ButtonWrapper>
          </S.Buttons>
        </VerticalMenu>
      </S.Wrapper>
    </>
  );
};

export default SuperAdmin;
