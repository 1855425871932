import { MenuItem, SelectChangeEvent } from '@mui/material';
import { Button, DatePicker, SelectInput } from 'components';
import {
  useClinicsGetQuery,
  useStaffAssignToClinicMutation,
} from 'store/api/clinics/clinicApi';
import * as S from './styles';
import { useEffect, useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { setMessage } from 'store/slices/message';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { Clinics } from 'types/ClinicTypes';
import { Roles } from 'types/Roles';
import { GetDoctorList } from './GetDoctorList';
import { GetLocalAdminList } from './GetLocalAdminList';
import { GetReceptionistList } from './GetReceptionistList';
import { useAppSelector } from 'hooks/useAppSelector';

type Props = {
  userId?: string;
  assignInfo?: Clinics[];
  onClose: () => void;
  role?: Roles;
  clinicId?: string;
  getReceptionistsList?: any;
  isSpecialists?: boolean;
};

const AssignClinicDialog = ({
  userId,
  assignInfo,
  onClose,
  role,
  clinicId,
  isSpecialists,
  getReceptionistsList,
}: Props) => {
  const clinics = useClinicsGetQuery({});
  const me = useAppSelector(state => state.auth);

  const [clinicName, setClinicName] = useState('');
  const [firstWorkDay, setFirstWorkDay] = useState<Dayjs>(dayjs());
  const [lastWorkDay, setLastWorkDay] = useState<Dayjs | string>('');
  const [selectedValue, setSelectedValue] = useState<any>(null);
  const [prevStartDate, setPrevStartDate] = useState<any>('');
  const [prevEndDate, setPrevEndDate] = useState<any>('');
  const [error, setError] = useState<any>(false);

  const [assignOn] = useStaffAssignToClinicMutation({});

  const dispatch = useAppDispatch();

  const disableButton = useMemo(
    () => !clinicName || !firstWorkDay,
    [clinicName, firstWorkDay],
  );

  const getTitle = useMemo(() => {
    switch (role) {
      case Roles.DOCTOR: {
        return isSpecialists ? 'Specialist' : 'Doctor';
      }
      case Roles.RECEPTIONIST: {
        return 'Receptionist';
      }
      case Roles.LOCATION_MANAGER: {
        return 'Local Admin';
      }
      default: {
        return 'Clinic';
      }
    }
  }, [role, isSpecialists]);

  // useEffect(() => {
  //   if (assignInfo) {
  //     setClinicName(assignInfo.id);
  //     setFirstWorkDay(dayjs(assignInfo.workingStartDate));
  //     assignInfo.workingEndDate && setLastWorkDay(dayjs(assignInfo.workingEndDate));
  //   }
  // }, [assignInfo]);

  const getPreviousWorkingDates = (
    assignInfo: Clinics[] | undefined,
    clinicId: string,
  ) => {
    if (!assignInfo && !clinicId) return null;

    const clinic = assignInfo?.find(clinic => clinic.id === clinicId);

    return clinic?.previosWorkingDates || null;
  };

  useEffect(() => {
    if (clinicId) {
      setClinicName(clinicId);
    }
  }, [clinicId]);

  useEffect(() => {
    if (selectedValue?.id) {
      setError(false);
    }
  }, [selectedValue]);

  return (
    <S.Content>
      <S.Article>Assign {getTitle}</S.Article>
      <S.InputWrapper>
        {!userId && role === Roles.DOCTOR && (
          <S.InputAutoCompleteWrapper>
            <GetDoctorList
              setSelectedValue={setSelectedValue}
              selectedValue={selectedValue}
              key={Roles.DOCTOR}
              isSpecialist={!!isSpecialists}
              getReceptionistsList={getReceptionistsList}
              error={error}
            />
          </S.InputAutoCompleteWrapper>
        )}
        {!userId && role === Roles.RECEPTIONIST && (
          <S.InputAutoCompleteWrapper>
            <GetReceptionistList
              setSelectedValue={setSelectedValue}
              selectedValue={selectedValue}
              key={Roles.RECEPTIONIST}
              getReceptionistsList={getReceptionistsList}
              error={error}
            />
          </S.InputAutoCompleteWrapper>
        )}
        {!userId && role === Roles.LOCATION_MANAGER && (
          <S.InputAutoCompleteWrapper>
            <GetLocalAdminList
              setSelectedValue={setSelectedValue}
              selectedValue={selectedValue}
              key={Roles.LOCATION_MANAGER}
              getReceptionistsList={getReceptionistsList}
              error={error}
            />
          </S.InputAutoCompleteWrapper>
        )}
      </S.InputWrapper>
      <S.InputWrapper>
        <SelectInput
          label="Clinic"
          isRequired
          value={clinicName}
          onChange={(e: SelectChangeEvent<unknown>) => {
            const selectedClinicId = e.target.value as string;
            setClinicName(selectedClinicId);
            const assignedClinic = assignInfo?.find(
              clinic => clinic.id === selectedClinicId && clinic.isWorkInClinic === false,
            );
            if (assignedClinic) {
              setPrevStartDate(assignedClinic.workingStartDate);
              setPrevEndDate(assignedClinic.workingEndDate);
            } else {
              setPrevStartDate('');
              setPrevEndDate('');
            }
          }}
          disabled={!!clinicId}
        >
          {clinics.currentData?.rows.map(clinic => {
            const isDisabled = assignInfo?.some(
              assignedClinic =>
                assignedClinic.id === clinic.id &&
                assignedClinic.isWorkInClinic !== false,
            );
            return me?.role === Roles.SUPER_ADMIN ? (
              <MenuItem key={clinic.id} value={clinic.id} disabled={isDisabled}>
                <S.MenuItemContent>{clinic.name}</S.MenuItemContent>
              </MenuItem>
            ) : (
              me.clinics?.some(i => i.id === clinic.id) && (
                <MenuItem key={clinic.id} value={clinic.id} disabled={isDisabled}>
                  <S.MenuItemContent>{clinic.name}</S.MenuItemContent>
                </MenuItem>
              )
            );
          })}
        </SelectInput>
      </S.InputWrapper>
      <S.InputWrapper>
        <DatePicker
          isRequired
          label="First work day"
          value={firstWorkDay}
          onChange={value => setFirstWorkDay(value)}
        />
        <DatePicker
          label="Last work day"
          value={lastWorkDay}
          onChange={value => setLastWorkDay(value)}
        />
      </S.InputWrapper>
      {getPreviousWorkingDates(assignInfo, clinicName)?.map(date => (
        <S.InputDateWrapper>
          <S.PrevDate>
            Previous start date:{' '}
            <S.EndDate>{dayjs(date.workingStartDate).format('MM/DD/YYYY')}</S.EndDate>
          </S.PrevDate>
          <S.PrevDate>
            End date:{' '}
            <S.EndDate>{dayjs(date.workingEndDate).format('MM/DD/YYYY')}</S.EndDate>
          </S.PrevDate>
        </S.InputDateWrapper>
      ))}
      <S.ButtonWrapper>
        <Button
          text="Save"
          disabled={disableButton}
          onClick={() => {
            if (!userId && (!selectedValue || !selectedValue.userId)) {
              setError(true);
              return;
            }
            setError(false);
            assignOn({
              clinicId: clinicName,
              userId: userId || selectedValue.userId,
              workingStartDate: dayjs(firstWorkDay).format('YYYY-MM-DD'),
              ...(lastWorkDay && {
                workingEndDate: dayjs(lastWorkDay).format('YYYY-MM-DD'),
              }),
            })
              .unwrap()
              .then(res => {
                setError(false);
                onClose();
                dispatch(
                  setMessage({
                    message: 'Clinic was successfully assigned to user',
                    type: 'success',
                  }),
                );
              })
              .catch(error => {
                setError(false);
                onClose();
                dispatch(setMessage({ message: error.data.message, type: 'error' }));
              });
          }}
        />
      </S.ButtonWrapper>
    </S.Content>
  );
};

export default AssignClinicDialog;
