import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Input, SelectInput } from '../../../../../../components';
import * as S from './styles';
import { AddRectSVG, DeleteBtnSVG } from '../../../../../../assets/icons';
import { useAppSelector } from '../../../../../../hooks/useAppSelector';
import { getFileIcon } from '../../../../../../helpers/getFileIcon';
import {
  useTemplatesCreateMutation,
  useTemplatesUpdateMutation,
} from '../../../../../../store/api/templates/templateApi';
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch';
import { setMessage } from '../../../../../../store/slices/message';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { useClinicsGetQuery } from '../../../../../../store/api/clinics/clinicApi';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Clinics } from '../../../../../../types/ClinicTypes';
import { TemplateType } from '../../../../../../types/TemplateType';
import { REQUIRED_FIELD } from '../../../../../../constants/constants';

type Props = {
  onClose: () => void;
  data?: TemplateType;
};

type FormValues = {
  note: string;
  name: string;
  tempClinics: Clinics[] | string[];
};

const CreateTemplateDialog: React.FC<Props> = ({ onClose, data }) => {
  const Shape = yup.object().shape({
    name: yup.string().required(REQUIRED_FIELD),
    note: yup.string(),
  });

  const [newFiles, setNewFiles] = useState<Array<File>>([]);
  const [arrTempClinics, setArrTempClinics] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);
  const selectedClinic = useAppSelector(state => state.selectedClinic);
  const me = useAppSelector(state => state.auth);
  const dispatch = useAppDispatch();

  const [createTemplate] = useTemplatesCreateMutation({});
  const [updateTemplate] = useTemplatesUpdateMutation({});
  const clinics = useClinicsGetQuery({});

  const handleChangeFile = (e: any) => {
    const file = e.target.files;

    if (file) {
      setNewFiles([...file]);
    }
  };

  const {
    values,
    resetForm,
    handleChange,
    handleSubmit,
    errors,
    setFieldValue,
    setValues,
    setFieldError,
  } = useFormik<FormValues>({
    initialValues: {
      name: '',
      note: '',
      tempClinics: [],
    },
    validateOnChange: false,
    validationSchema: Shape,
    onSubmit: () => {},
  });

  const disableButton = useMemo(
    () => !values.name || newFiles.length === 0 || values.tempClinics.length === 0,
    [values.name, newFiles, values.tempClinics],
  );

  const handleCreate = () => {
    const data = {
      clinicId: values.tempClinics,
      name: values.name,
      note: values.note,
      file: newFiles[0],
    };
    onClose();
    createTemplate(data)
      .unwrap()
      .then(res => {
        onClose();
        dispatch(
          setMessage({
            message: 'Template was successfully created',
            type: 'success',
          }),
        );
      })
      .catch(error => {
        dispatch(setMessage({ message: error.data.message, type: 'error' }));
      });
  };

  const handleUpdate = () => {
    if (data) {
      const tempData = {
        id: data?.id,
        clinicId: values.tempClinics,
        name: values.name,
        note: values.note,
        ...(newFiles[0] !== data.file && { file: newFiles[0] }),
      };

      onClose();
      updateTemplate(tempData)
        .unwrap()
        .then(res => {
          dispatch(
            setMessage({
              message: 'Template was successfully updated',
              type: 'success',
            }),
          );
        })
        .catch(error => {
          dispatch(setMessage({ message: error.data.message, type: 'error' }));
        });
    }
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFieldError(e.target.id, '');
    handleChange(e);
  };

  useEffect(() => {
    if (data) {
      setValues({
        ...values,
        name: data.name,
        note: data.note,
        tempClinics: data.clinics.map(item => item.id),
      });

      setNewFiles([data.file]);
    }
  }, [data]);

  return (
    <S.Content>
      <S.Article>{data ? 'Edit Template' : 'Add Template'} </S.Article>
      <Input
        id="name"
        isRequired
        name="name"
        label="Template name"
        value={values.name}
        onChange={handleChangeInput}
        error={!!errors.name}
        helperText={errors.name}
      />
      <Input
        multiline
        id="note"
        name="note"
        rows={4}
        label="Note"
        value={values.note}
        onChange={handleChangeInput}
        error={!!errors.note}
        helperText={errors.note}
      />
      <SelectInput
        label="Clinic names"
        isRequired
        multiple
        id="tempClinics"
        name="tempClinics"
        error={!!errors.tempClinics}
        helperText={errors.tempClinics}
        value={values.tempClinics}
        onChange={(e: SelectChangeEvent<unknown>) => {
          setFieldError('tempClinics', '');
          handleChange(e);
        }}
        renderValue={(selected: any) => {
          if (selected.length === 0) return '';
          return selected
            .map((value: any) => {
              const clinic = clinics.currentData?.rows.find(
                clinic => clinic.id === value,
              );
              return clinic ? clinic.name : '';
            })
            .map((name: string, index: number, array: string[]) => (
              <>
                {name}
                {index !== array.length - 1 && <br />}
              </>
            ));
        }}
      >
        {me?.clinics && me.clinics.length === 0
          ? clinics.currentData?.rows.map(clinic => (
              <MenuItem key={clinic.id} value={clinic.id}>
                <S.MenuItemContent>
                  {/* <CheckBox checked={clinic.indexOf(clinic.id) > -1} /> */}
                  {clinic.name}
                </S.MenuItemContent>
              </MenuItem>
            ))
          : me.clinics.map(clinic => (
              <MenuItem key={clinic.id} value={clinic.id}>
                <S.MenuItemContent>
                  {/* <CheckBox checked={clinic.indexOf(clinic.id) > -1} /> */}
                  {clinic.name}
                </S.MenuItemContent>
              </MenuItem>
            ))}
      </SelectInput>
      <S.DownloadBtnWrapper>
        <label htmlFor="file">
          <S.Btn onClick={() => {}}>
            <AddRectSVG className="svg" />
            Add Template File
          </S.Btn>
          <input
            ref={inputRef}
            type="file"
            id="file"
            onChange={handleChangeFile}
            accept="image/png, image/jpeg, image/jpg, application/pdf, .doc, .docx, .xslx, .xsl, .csv, .mp4"
            hidden
            multiple={false}
          />
        </label>
      </S.DownloadBtnWrapper>
      {newFiles.map((item: File, index) => {
        return (
          <S.RowItem key={item.name + index}>
            {getFileIcon(item.name.slice(-3))}
            <S.RowText>{item.name}</S.RowText>
            <DeleteBtnSVG
              onClick={() => {
                if (inputRef.current) {
                  inputRef.current.value = '';
                }
                setNewFiles([]);
              }}
            />
          </S.RowItem>
        );
      })}
      <S.ButtonWrapper>
        <Button
          text={data ? 'Update' : 'Add'}
          onClick={data ? handleUpdate : handleCreate}
          disabled={disableButton}
        />
      </S.ButtonWrapper>
    </S.Content>
  );
};

export default CreateTemplateDialog;
