import { createSlice } from '@reduxjs/toolkit';

type Patient = {
  add: boolean;
};

const initialState: Patient = {
  add: false,
};

const extraPatientSlice = createSlice({
  name: 'extraPatient',
  initialState,
  reducers: {
    isAddPatient: (state, action) => {
      return {
        ...state,
        add: action.payload.add,
      };
    },
  },
});

const { reducer, actions } = extraPatientSlice;

export const { isAddPatient } = actions;
export default reducer;
