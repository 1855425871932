import styled, { css } from 'styled-components';
import { devices } from 'constants/device';

export const Wrapper = styled.div<{
  background: string;
  border: string;
  canselStatus: boolean;
}>`
  border-radius: 5px;
  gap: 4px;
  width: 100%;
  min-height: 46px;
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  overflow: hidden;
  background: ${props => (props.canselStatus ? 'transparent' : props.background)};
  ${props =>
    props.border &&
    css`
      border: ${props.canselStatus ? '1px solid #c66060' : `0.5px solid ${props.border}`};
    `}
`;

export const StatusWrap = styled.div`
  padding-top: 2px;
`;

export const ClaimCountCard = styled.div<{ canselStatus: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  min-width: 65px;
  line-height: normal;
  padding: 2px 10px;
  border-radius: 3px;
  background: var(--main, #23b8eb);
  opacity: ${props => props.canselStatus && 0.4}!important;
`;

export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const LeftSide = styled.div<{ canselStatus: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  opacity: ${props => props.canselStatus && 0.4}!important;
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 220px;

  height: 100%;
`;

export const UpperLine = styled.div`
  display: flex;
  width: 100%;
  padding: 6px 0px 6px 6px;
  gap: 2px 6px;
  align-items: center;
  flex-wrap: wrap;
`;

export const Cell = styled.div`
  display: flex;
  width: calc(18% - 5px);
`;
export const CellAccountN = styled.div`
  display: flex;
  width: calc(16% - 5px);
`;
export const CellReason = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(22% - 5px);
`;
export const NameAccountInsuranceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(44% - 5px);
`;
export const NameAccountWrapper = styled.div`
  display: flex;
  //width: calc(50% - 5px);
`;

export const CellDOB = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(30% - 5px);
`;
export const DateDiv = styled.div`
  display: flex;
  width: 120px;
  min-width: 120px;
  margin-left: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  @media ${devices.xs} {
    display: none;
  }
`;

export const AppointmentType = styled.div<{ canselStatus: boolean }>`
  display: flex;
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  margin-right: 5px;
  margin-top: 2px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  opacity: ${props => props.canselStatus && 0.4}!important;
`;

export const StatusProcess = styled.div`
  display: flex;
  color: var(--text, #c66060);
  font-family: Inter;
  font-size: 12px;
  margin-right: 10px;
  margin-top: 6px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
`;

export const RecordTime = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const NumberDay = styled.div`
  color: #0084b1;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const SelectBlock = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
  height: 100%;
`;

export const ArticleSpan = styled.span`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const MovableBlock = styled.div`
  display: flex;
  justify-content: end;
  align-items: end;

  svg:hover {
    cursor:
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 29 29' fill='none'%3E%3Cpath d='M24.0625 7.74982H23.5625H23.1094H22.6094V7.24982V5.48263C22.6094 4.25645 21.635 3.24202 20.4275 3.2185C19.1829 3.20056 18.1719 4.19913 18.1719 5.43732V7.24982V7.74982H17.6719H17.2188H16.7188V7.24982V4.57638C16.7188 3.3502 15.7444 2.33577 14.5368 2.31225C13.2923 2.29431 12.2812 3.29288 12.2812 4.53107V7.24982V7.74982H11.7812H11.3281H10.8281V7.24982V5.48263C10.8281 4.25645 9.85377 3.24202 8.64621 3.2185C7.40169 3.20056 6.39062 4.19913 6.39062 5.43732V13.1404V14.2527L5.55873 13.5144L5.10561 13.1123L4.9375 12.9631V12.7383V10.0139C4.9375 8.78768 3.96312 7.77325 2.75554 7.74975C1.51104 7.73183 0.5 8.7304 0.5 9.96857V13.7295C0.5 15.1385 1.10155 16.4812 2.15654 17.4231C2.15663 17.4232 2.15671 17.4232 2.1568 17.4233L8.48279 23.047L8.48345 23.0476C9.16706 23.6575 9.5625 24.535 9.5625 25.4541V25.8336C9.5625 26.3108 9.9447 26.693 10.4219 26.693H24.0156C24.4928 26.693 24.875 26.3108 24.875 25.8336V25.6694C24.875 24.8768 25.0359 24.0924 25.338 23.3649L25.339 23.3625L28.1144 16.7752L28.1148 16.7743C28.3711 16.1695 28.5 15.5167 28.5 14.8567V7.29513C28.5 6.06692 27.5239 5.04943 26.3191 5.03102L24.0625 7.74982ZM24.0625 7.74982V7.24982M24.0625 7.74982V7.24982M24.0625 7.24982C24.0625 6.01134 25.074 5.01259 26.319 5.03101L24.0625 7.24982Z' fill='white' stroke='black'/%3E%3C/svg%3E")
        9 9,
      pointer;
  }
`;

export const Article = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  &.ml {
    margin-left: 2px;
  }
`;

export const Name = styled.div`
  color: var(--main, #0084b1);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;
`;

export const Text = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  max-width: 90%;
  white-space: nowrap;
  &.italic {
    font-weight: 500;
    font-style: italic;
  }
`;

export const Span = styled.span`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;
`;

export const UnderLine = styled.div`
  display: flex;
  width: 100%;
  padding: 6px 0px 6px 6px;
  gap: 0px 6px;
  align-items: center;
  flex-wrap: wrap;
`;
