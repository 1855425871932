import { Speciality } from 'types/enums/Speciality';
import { TemplateNameType } from 'types/TemplateType';
import * as yup from 'yup';

export const constantTimePicker = [
  { name: '8:00 AM', value: '08:00' },
  { name: '8:15 AM', value: '08:15' },
  { name: '8:30 AM', value: '08:30' },
  { name: '8:45 AM', value: '08:45' },
  { name: '9:00 AM', value: '09:00' },
  { name: '9:15 AM', value: '09:15' },
  { name: '9:30 AM', value: '09:30' },
  { name: '9:45 AM', value: '09:45' },
  { name: '10:00 AM', value: '10:00' },
  { name: '10:15 AM', value: '10:15' },
  { name: '10:30 AM', value: '10:30' },
  { name: '10:45 AM', value: '10:45' },
  { name: '11:00 AM', value: '11:00' },
  { name: '11:15 AM', value: '11:15' },
  { name: '11:30 AM', value: '11:30' },
  { name: '11:45 AM', value: '11:45' },
  { name: '12:00 PM', value: '12:00' },
  { name: '12:15 PM', value: '12:15' },
  { name: '12:30 PM', value: '12:30' },
  { name: '12:45 PM', value: '12:45' },
  { name: '1:00 PM', value: '13:00' },
  { name: '1:15 PM', value: '13:15' },
  { name: '1:30 PM', value: '13:30' },
  { name: '1:45 PM', value: '13:45' },
  { name: '2:00 PM', value: '14:00' },
  { name: '2:15 PM', value: '14:15' },
  { name: '2:30 PM', value: '14:30' },
  { name: '2:45 PM', value: '14:45' },
  { name: '3:00 PM', value: '15:00' },
  { name: '3:15 PM', value: '15:15' },
  { name: '3:30 PM', value: '15:30' },
  { name: '3:45 PM', value: '15:45' },
  { name: '4:00 PM', value: '16:00' },
  { name: '4:15 PM', value: '16:15' },
  { name: '4:30 PM', value: '16:30' },
  { name: '4:45 PM', value: '16:45' },
  { name: '5:00 PM', value: '17:00' },
  { name: '5:15 PM', value: '17:15' },
  { name: '5:30 PM', value: '17:30' },
  { name: '5:45 PM', value: '17:45' },
  { name: '6:00 PM', value: '18:00' },
  { name: '6:15 PM', value: '18:15' },
  { name: '6:30 PM', value: '18:30' },
  { name: '6:45 PM', value: '18:45' },
  { name: '7:00 PM', value: '19:00' },
  { name: '7:15 PM', value: '19:15' },
  { name: '7:30 PM', value: '19:30' },
  { name: '7:45 PM', value: '19:45' },
];

export const constantTimePickerOUT = [
  { name: '8:00 AM', value: '08:00' },
  { name: '8:15 AM', value: '08:15' },
  { name: '8:30 AM', value: '08:30' },
  { name: '8:45 AM', value: '08:45' },
  { name: '9:00 AM', value: '09:00' },
  { name: '9:15 AM', value: '09:15' },
  { name: '9:30 AM', value: '09:30' },
  { name: '9:45 AM', value: '09:45' },
  { name: '10:00 AM', value: '10:00' },
  { name: '10:15 AM', value: '10:15' },
  { name: '10:30 AM', value: '10:30' },
  { name: '10:45 AM', value: '10:45' },
  { name: '11:00 AM', value: '11:00' },
  { name: '11:15 AM', value: '11:15' },
  { name: '11:30 AM', value: '11:30' },
  { name: '11:45 AM', value: '11:45' },
  { name: '12:00 PM', value: '12:00' },
  { name: '12:15 PM', value: '12:15' },
  { name: '12:30 PM', value: '12:30' },
  { name: '12:45 PM', value: '12:45' },
  { name: '1:00 PM', value: '13:00' },
  { name: '1:15 PM', value: '13:15' },
  { name: '1:30 PM', value: '13:30' },
  { name: '1:45 PM', value: '13:45' },
  { name: '2:00 PM', value: '14:00' },
  { name: '2:15 PM', value: '14:15' },
  { name: '2:30 PM', value: '14:30' },
  { name: '2:45 PM', value: '14:45' },
  { name: '3:00 PM', value: '15:00' },
  { name: '3:15 PM', value: '15:15' },
  { name: '3:30 PM', value: '15:30' },
  { name: '3:45 PM', value: '15:45' },
  { name: '4:00 PM', value: '16:00' },
  { name: '4:15 PM', value: '16:15' },
  { name: '4:30 PM', value: '16:30' },
  { name: '4:45 PM', value: '16:45' },
  { name: '5:00 PM', value: '17:00' },
  { name: '5:15 PM', value: '17:15' },
  { name: '5:30 PM', value: '17:30' },
  { name: '5:45 PM', value: '17:45' },
  { name: '6:00 PM', value: '18:00' },
  { name: '6:15 PM', value: '18:15' },
  { name: '6:30 PM', value: '18:30' },
  { name: '6:45 PM', value: '18:45' },
  { name: '7:00 PM', value: '19:00' },
  { name: '7:15 PM', value: '19:15' },
  { name: '7:30 PM', value: '19:30' },
  { name: '7:45 PM', value: '19:45' },
  { name: '8:00 PM', value: '20:00' },
  { name: '8:15 PM', value: '20:15' },
  { name: '8:30 PM', value: '20:30' },
  { name: '8:45 PM', value: '20:45' },
  { name: '9:00 PM', value: '21:00' },
  { name: '9:15 PM', value: '21:15' },
];

export const visitLengthPicker = [
  { name: '15 min', value: 15 },
  { name: '30 min', value: 30 },
  { name: '45 min', value: 45 },
  { name: '1 hr', value: 60 },
];

export const visitLength = [15, 30, 45, 60];

export const repeatPicker = [
  { name: '0 time', value: 0 },
  { name: '1 time', value: 1 },
  { name: '2 times', value: 2 },
  { name: '3 times', value: 3 },
  { name: '4 times', value: 4 },
  { name: '5 times', value: 5 },
  { name: '6 times', value: 6 },
  { name: '7 times', value: 7 },
  { name: '8 times', value: 8 },
  { name: '9 times', value: 9 },
  { name: '10 times', value: 10 },
  { name: '11 times', value: 11 },
  { name: '12 times', value: 12 },
  { name: '13 times', value: 13 },
  { name: '14 times', value: 14 },
  { name: '15 times', value: 15 },
];

export const daysBetweenVisits = [
  { name: '', value: 0 },
  { name: '1 day', value: 1 },
  { name: '2 days', value: 2 },
  { name: '3 days', value: 3 },
  { name: '4 days', value: 4 },
  { name: '5 days', value: 5 },
  { name: '6 days', value: 6 },
  { name: '7 days', value: 7 },
  { name: '8 days', value: 8 },
  { name: '9 days', value: 9 },
  { name: '10 days', value: 10 },
  { name: '11 days', value: 11 },
  { name: '12 days', value: 12 },
];

export const monthsName = [
  { name: 'January', value: 0 },
  { name: 'February', value: 1 },
  { name: 'March', value: 2 },
  { name: 'April', value: 3 },
  { name: 'May', value: 4 },
  { name: 'June', value: 5 },
  { name: 'July', value: 6 },
  { name: 'August', value: 7 },
  { name: 'September', value: 8 },
  { name: 'October', value: 9 },
  { name: 'November', value: 10 },
  { name: 'December', value: 11 },
];

export const modeTypes = ['Day', 'Week', 'Month', 'Year'];

export const withoutYearModeTypes = ['Day', 'Week', 'Month'];

export const appointmentStatuses = ['pending', 'completed', 'canceled', 'active'];

export const paymentsMethods = [
  { name: 'Credit card', value: 'client_by_card' },
  // { name: "Co-payment", value: "co_payment" },
  { name: 'Cash', value: 'client_by_cash' },
  // { name: "Check", value: "check" },
];

export const paymentStatuses = [
  { name: 'Paid', value: 'paid' },
  { name: 'Unpaid', value: 'unpaid' },
];

export const dayNames = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export enum AppointmentCancell {
  OFFICE = 'Cancelled by Office',
  PROVIDER = 'Cancelled by Provider',
  PATIENT = 'Cancelled by Patient',
  LAST_MIN_PATIENT = 'Last min cancelled by Patient',
  RESCHEDULED = 'Rescheduled',
  PATIENT_DID_NOT_COME = 'Patient Did not come',
}

export const race = [
  {
    name: 'American Indian or Alaska Native',
    value: 'American Indian or Alaska Native',
  },
  { name: 'Asian', value: 'Asian' },
  { name: 'Black or African American', value: 'Black or African American' },
  {
    name: 'Native Hawaiian or Other Pacific Islander',
    value: 'Native Hawaiian or Other Pacific Islander',
  },
  { name: 'White', value: 'White' },
];

export const userStatuses: any[] = ['active', 'blocked', 'pending', 'unreg'];

export const templateEnum = [
  //{ name: 'Forms', value: TemplateNameType.CLIENT },
  { name: 'PDF Form + Sign', value: TemplateNameType.PDF },
  { name: 'Digital Form', value: TemplateNameType.DIGITAL },
];

export const REQUIRED_FIELD = 'Is required';
export const TOO_LONG = 'Too Long!';
export const MORE_ZERO = 'Field must be greater than or equal to zero';
export const MORE_ONE = 'Field must be greater than or equal to 1';
export const MAX_NUMBER = 'Field must be less than or equal to 1 000 000';
export const INVALID_EMAIL = 'Set email in valid format';
export const INVALID_PHONE = 'International format required: +1xxxxxxxxxx';

export const patternTwoDigitsAfterComma = /^\d+(\.\d{0,2})?$/;

export const commonMoneyValidator = yup
  .number()
  .test(
    'is-decimal',
    'Field must be a decimal with maximum two digits after comma',
    (val: any) => {
      if (val !== undefined) {
        return patternTwoDigitsAfterComma.test(val);
      }
      return true;
    },
  )
  .min(0, MORE_ZERO)
  .max(1000000, MAX_NUMBER)
  .nullable();

export const emailValidator = yup
  .string()
  .matches(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/, INVALID_EMAIL);

export const phoneValidator = yup
  .string()
  .matches(/^\+[1-9]\d{1,14}$/, INVALID_PHONE)
  .required(REQUIRED_FIELD)
  .min(12, INVALID_PHONE)
  .max(12, INVALID_PHONE);

export const phoneValidatorNullable = yup
  .string()
  .matches(/^\+[1-9]\d{1,14}$/, INVALID_PHONE)
  .min(12, INVALID_PHONE)
  .max(12, INVALID_PHONE);

export const ChooseProviderTab = { Doctor: 'Provider', Clinic: 'Facility' };
