import { useMemo, useState } from 'react';
import { Dialog, SuccessDialog } from '../../../../../..';
import { DeleteAppointmentSVG, DuplicateSVG } from 'assets/icons';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { showDrawer } from 'store/slices/drawerSlice';
import { AppointmentsNew, Visits } from 'types/AppointmentNewTypes';
import { DrawerMode } from 'types/DrawerMode';
import CheckBox from 'components/atoms/CheckBox';
import ConfirmDeleteDialog from 'components/molecules/ConfirmDeleteDialog';
import AppointmentCard from '../AppointmentCard';
import * as S from './styles';
import {
  useAppointmentDeleteMutation,
  useDeleteVisitServiceMutation,
} from 'store/api/appointment/appointmentApi';
import { setMessage } from 'store/slices/message';
import { AppointmentStatus } from 'types/StatusTypes';
import { useAppSelector } from 'hooks/useAppSelector';
import { Roles } from 'types/Roles';
import { Fade, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import { useClaimManualCreateMutation } from 'store/api/claims/claimsApi';

type Props = {
  appointment: AppointmentsNew;
  time: string;
  visit: Visits;
};

const PlannerRecord: React.FC<Props> = ({ appointment, time, visit }) => {
  const dispatch = useAppDispatch();

  const userInfo = useAppSelector(state => state.auth);

  const [openDialog, setOpenDialog] = useState(false);
  const [addStatusClaim, setAddStatusClaim] = useState(false);

  const [openCreateClaimDialog, setOpenCreateClaimDialog] = useState(false);

  const [openExistingClaim, setOpenExistingClaim] = useState(false);

  const [deleteAppointment] = useAppointmentDeleteMutation({});

  const [deleteVisit] = useDeleteVisitServiceMutation({});

  const [createManual] = useClaimManualCreateMutation({});

  const handleChangeDialog = () => {
    setOpenDialog(!openDialog);
  };

  const handleShowExisting = () => {
    setOpenCreateClaimDialog(!openCreateClaimDialog);
    setOpenExistingClaim(!openExistingClaim);
  };

  const handleDelete = () => {
    appointment.visits.length > 1
      ? deleteVisit({ id: visit.id })
          .unwrap()
          .then(res => {
            dispatch(
              setMessage({
                message: 'Service was successfully deleted from appointment',
                type: 'success',
              }),
            );
          })
          .catch(error => {
            dispatch(
              setMessage({
                message: error.data.message,
                type: 'error',
              }),
            );
          })
      : deleteAppointment({ id: appointment.id })
          .unwrap()
          .then(res => {
            dispatch(
              setMessage({
                message: 'Appointment was successfully deleted',
                type: 'success',
              }),
            );
          })
          .catch(error => {
            dispatch(
              setMessage({
                message: error.data.message,
                type: 'error',
              }),
            );
          });
  };

  const getCoveredService = useMemo(() => {
    return appointment?.visits?.find(
      i => i.typeServiceId === appointment?.mainVisitReason,
    );
  }, [appointment]);

  return (
    <S.RecordItem>
      <Dialog
        open={openCreateClaimDialog}
        onClose={() => setOpenCreateClaimDialog(!openCreateClaimDialog)}
      >
        <SuccessDialog
          onClose={() => setOpenCreateClaimDialog(!openCreateClaimDialog)}
          titleText="Create manual Claim"
          helperText={`Do you want to create ${
            appointment.authSigs
          } manual claim, covered service: ${getCoveredService?.reason ?? '-'} ?`}
          onSuccess={() => {
            setAddStatusClaim(false);
            createManual({
              appointmentId: appointment.id,
            })
              .unwrap()
              .then(res => {
                dispatch(
                  setMessage({
                    message: 'Manual claim was successfully created',
                    type: 'success',
                  }),
                );
                setAddStatusClaim(true);
              })
              .catch(error => {
                dispatch(setMessage({ message: error.data.message, type: 'error' }));
                setAddStatusClaim(false);
              });
          }}
          successText="Yes"
        />
      </Dialog>
      {/* <Dialog
        open={openExistingClaim}
        onClose={() => setOpenExistingClaim(!openExistingClaim)}
      >
        <CreateExistingClaimDialog
          appointment={appointment}
          onClose={() => setOpenExistingClaim(!openExistingClaim)}
        />
      </Dialog> */}
      <S.BtnColumn>
        {appointment.status === AppointmentStatus.PENDING &&
          userInfo.role !== Roles.BILLING &&
          userInfo.role !== Roles.DOCTOR && (
            <>
              <Dialog open={openDialog} onClose={handleChangeDialog}>
                <ConfirmDeleteDialog
                  onClose={handleChangeDialog}
                  fullTitle={
                    appointment.visits.length > 1
                      ? `Are you sure want to delete current service from appointment #${appointment.id.slice(
                          0,
                          8,
                        )}?`
                      : 'Are you sure you want to delete the entire appointment?'
                  }
                  helperText={
                    appointment.visits.length > 1
                      ? 'You will delete only one current service from appointment'
                      : 'You will delete the entire appointment and lose progress'
                  }
                  onSuccess={handleDelete}
                />
              </Dialog>
              <DeleteAppointmentSVG onClick={handleChangeDialog} />
            </>
          )}
      </S.BtnColumn>
      {(userInfo.role === Roles.BILLING ||
        userInfo.role === Roles.LOCATION_MANAGER ||
        userInfo.role === Roles.SUPER_ADMIN) && (
        <Tooltip
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: '#0084B1',
                fontFamily: 'Inter',
              },
            },
          }}
          title={
            !!appointment.createManualClaimDate
              ? `MANUAL: ${appointment.countManualClaims}, ${dayjs(
                  appointment.createManualClaimDate,
                ).format('MM/DD/YYYY h:mm A')}`
              : ''
          }
          TransitionComponent={Fade}
          TransitionProps={{
            timeout: 300,
          }}
        >
          <S.BtnColumn
            className="signatures"
            onClick={() => {
              appointment.isCheckBilling &&
                userInfo.role === Roles.BILLING &&
                appointment.countManualClaims === 0 &&
                !addStatusClaim &&
                visit.typeServiceId === getCoveredService?.typeServiceId &&
                appointment.status === AppointmentStatus.COMPLETED &&
                setOpenCreateClaimDialog(!openCreateClaimDialog);
            }}
          >
            {appointment.isCheckBilling &&
              [
                Roles.BILLING,
                Roles.SUPER_ADMIN,
                Roles.LOCATION_MANAGER,
                Roles.RECEPTIONIST,
              ].includes(userInfo.role as Roles) &&
              visit.typeServiceId === getCoveredService?.typeServiceId && (
                <CheckBox checked={appointment.countManualClaims > 0 || addStatusClaim} />
              )}
          </S.BtnColumn>
        </Tooltip>
      )}
      {userInfo.role !== Roles.BILLING && (
        <S.BtnColumn>
          <DuplicateSVG
            onClick={() =>
              dispatch(
                showDrawer({
                  show: true,
                  mode: DrawerMode.DUPL_APPOINTMENT,
                  props: appointment,
                }),
              )
            }
          />
        </S.BtnColumn>
      )}
      <AppointmentCard appointment={appointment} time={time} visit={visit} />
    </S.RecordItem>
  );
};

export default PlannerRecord;
