import React, { useState } from 'react';
import CircularLoader from 'components/atoms/CircuralLoader';
import Button from 'components/atoms/Button';

import * as S from './styles';
import { FacilityTable } from '../ChooseDoctorOrFacility/FacilityTable';

type Props = {
  onApplyClick: (id: string | null) => void;
  currentValue?: string;
  title?: string;
  defaultDoctorTab?: boolean;
  claimId?: number;
  clinicId?: string | null;
  invoiceItemId?: number | null;
};

export const ChooseBillingLocation = ({
  onApplyClick,
  currentValue,
  title,
  claimId,
  clinicId,
  invoiceItemId,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const [selectedItem, setSelectedItem] = useState<string | null>(null);

  return (
    <S.Content>
      {isLoading && (
        <S.Loader>
          <CircularLoader color="#0084B1" size={16} />
        </S.Loader>
      )}
      <S.Article>{title}</S.Article>

      <FacilityTable
        currentValue={currentValue}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        setIsLoading={setIsLoading}
        claimId={claimId}
        clinicId={clinicId}
      />
      <S.ButtonWrapper>
        <Button text="Apply" onClick={() => onApplyClick(selectedItem)} />
      </S.ButtonWrapper>
    </S.Content>
  );
};
