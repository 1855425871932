import { FormikProps } from 'formik';
import Input from 'components/atoms/Input';
import * as S from './styles';
import { AppointmentFormValues } from '../../AppointmentDrawer';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { UserProfile } from 'types/UserProfileTypes';
import { AppointmentsNew } from 'types/AppointmentNewTypes';
import { AppointmentStatus } from 'types/StatusTypes';
import { useAppSelector } from 'hooks/useAppSelector';
import { AddCircleBlueSVG, ClearFiltersSVG, UnlockSVG } from 'assets/icons';
import { FirstNameV2 } from './FirstName';
import { LastNameV2 } from './LastName';
import Phone from './Phone';
import Email from './Email';
import { DOBV2 } from './DOB';
import dayjs from 'dayjs';
import { Dialog, SuccessDialog } from '../../../..';
import { useUnLockPendingAppointmentMutation } from 'store/api/appointment/appointmentApi';
import { showDrawer } from 'store/slices/drawerSlice';
import { DrawerMode } from 'types/DrawerMode';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { Roles } from 'types/Roles';
import { useDebounce } from 'use-debounce';
import { useLazyUsersGetDeepSearchQuery } from 'store/api/searchApi/searchApi';
import useInfinityScroll from 'hooks/useInfinityScroll';
import { isAddPatient } from '../../../../../store/slices/extraPatientSlice';

type Props = {
  formik: FormikProps<AppointmentFormValues>;
  userProfile?: UserProfile;
  selectedAppointment?: AppointmentsNew;
  duplAppointment?: AppointmentsNew;
  byClick?: boolean;
};

const PatientInformationBlock: React.FC<Props> = ({
  formik,
  userProfile,
  selectedAppointment,
  duplAppointment,
  byClick,
}) => {
  const dispatch = useAppDispatch();
  const me = useAppSelector(state => state.auth);
  const extraPatient = useAppSelector(state => state.extraPatient.add);
  const [selectedValue, setSelectedValue] = useState<any>('');
  const [emptyDatePicker, setEmptyDatePicker] = useState(false);
  const [page, setPage] = useState(1);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const drawer = useAppSelector(state => state.drawer);
  const [firstName] = useDebounce(formik.values.firstName, 500);
  const [lastName] = useDebounce(formik.values.lastName, 500);
  const [phone] = useDebounce(formik.values.phone, 500);
  const [email] = useDebounce(formik.values.email, 500);
  const [dateOfBirth] = useDebounce(formik.values.dateOfBirth, 500);
  const hasSetExtraPatient = useRef(false);
  const [getList, getSearchList] = useLazyUsersGetDeepSearchQuery({});
  const [unlockAppointment] = useUnLockPendingAppointmentMutation({});

  const getData = (currentPage: number) => {
    getList({
      page: currentPage,
      take: 15,
      ...(firstName && !selectedValue && { firstName }),
      ...(lastName && !selectedValue && { lastName }),
      ...(phone && !selectedValue && { phone }),
      ...(dateOfBirth &&
        !selectedValue && {
          dateOfBirth: formik.values.dateOfBirth
            ? dayjs(dateOfBirth).format('YYYY-MM-DD')
            : undefined,
        }),
      ...(email && !selectedValue && { email }),
      // ...(formik.values.clinicId && { clinicId: formik.values.clinicId }),
    });
  };

  const { setLastElement } = useInfinityScroll({
    fetchNextData: () => setPage(page + 1),
    hasMore: page < (getSearchList.data?.lastPage ?? 0),
  });

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    formik.setFieldError(e.target.id, '');
    formik.handleChange(e);
  };

  const handleClearFilters = () => {
    formik.setValues({
      ...formik.values,
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      dateOfBirth: '',
      userId: '',
    });
    setSelectedValue('');
    setEmptyDatePicker(true);
  };

  useEffect(() => {
    if (
      !userProfile &&
      !duplAppointment &&
      (!selectedAppointment || selectedAppointment?.isPossibleToUpdate) &&
      formik.values.clinicId &&
      !firstName &&
      !lastName &&
      !phone &&
      !email &&
      !dateOfBirth
    ) {
      getData(page);
    }
  }, [firstName, lastName, phone, email, dateOfBirth, page, formik.values.clinicId]);

  useEffect(() => {
    if (
      !userProfile &&
      !duplAppointment &&
      (!selectedAppointment || selectedAppointment?.isPossibleToUpdate) &&
      (firstName || lastName || phone || email || dateOfBirth) &&
      formik.values.clinicId
    ) {
      getData(page);
    }
  }, [firstName, lastName, phone, email, dateOfBirth, formik.values.clinicId]);

  useEffect(() => {
    if (userProfile) {
      formik.setValues({
        ...formik.values,
        firstName: userProfile.profile?.firstName,
        lastName: userProfile.profile?.lastName,
        phone: userProfile.phone,
        email: userProfile.email,
        dateOfBirth: userProfile.profile?.dateOfBirth
          ? dayjs(userProfile.profile?.dateOfBirth)
          : '',
        userId: userProfile.id || '',
      });
    }
  }, [userProfile]);

  useEffect(() => {
    if (selectedValue) {
      formik.setValues({
        ...formik.values,
        firstName: selectedValue.profile?.firstName || '',
        lastName: selectedValue.profile?.lastName || '',
        phone: selectedValue?.phone || '',
        email: selectedValue?.email || '',
        userId: selectedValue?.id || '',
        dateOfBirth: selectedValue.profile?.dateOfBirth || '',
      });
    }
  }, [selectedValue]);

  useEffect(() => {
    if (
      extraPatient &&
      getSearchList?.data?.rows?.length &&
      !hasSetExtraPatient.current
    ) {
      setSelectedValue(getSearchList?.data?.rows[0]);
      hasSetExtraPatient.current = true;
      dispatch(isAddPatient({ add: false }));
    }
  }, [getSearchList?.data?.rows.length, extraPatient]);

  const handleAddPatient = () => {
    dispatch(
      showDrawer({
        show: true,
        mode: DrawerMode.ADD_EXTRA_PATIENTS,
        props: drawer.props,
      }),
    );
  };
  return (
    <S.PatientInformation>
      <Dialog
        open={openSuccessDialog}
        onClose={() => setOpenSuccessDialog(!openSuccessDialog)}
      >
        <SuccessDialog
          onClose={() => setOpenSuccessDialog(!openSuccessDialog)}
          titleText="Are you sure you want to make a change to previously entered information?"
          helperText="This will cause a modification of the corresponding fields in Appointment"
          onSuccess={() => {
            unlockAppointment({
              id: selectedAppointment?.id,
            })
              .unwrap()
              .then(res => {
                dispatch(
                  showDrawer({
                    show: true,
                    mode: DrawerMode.INFO_APPOINTMENT,
                    props: res,
                  }),
                );
              });
          }}
        />
      </Dialog>
      <S.SubArticle>
        Patient information{' '}
        {byClick &&
        (selectedValue || selectedAppointment?.isPossibleToUpdate === true) ? (
          <S.SVG onClick={() => handleClearFilters()}>
            <ClearFiltersSVG />
            Clear user information
          </S.SVG>
        ) : (
          selectedAppointment?.status !== AppointmentStatus.CANCELED && (
            <S.AddUserButton onClick={handleAddPatient}>
              <AddCircleBlueSVG />
              Add New Patient
            </S.AddUserButton>
          )
        )}
        {selectedAppointment?.isPossibleToUpdate === false &&
          selectedAppointment?.status === AppointmentStatus.PENDING && (
            <S.SVG onClick={() => setOpenSuccessDialog(true)}>
              <UnlockSVG />
              Unlock for editing
            </S.SVG>
          )}
      </S.SubArticle>
      <S.InputRow>
        <FirstNameV2
          formik={formik}
          userProfile={userProfile}
          selectedAppointment={selectedAppointment}
          duplAppointment={duplAppointment}
          byClick={byClick}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          setLastElement={setLastElement}
          data={getSearchList?.data?.rows}
          isLoading={getSearchList.isFetching}
          setPage={setPage}
        />
        <LastNameV2
          formik={formik}
          userProfile={userProfile}
          selectedAppointment={selectedAppointment}
          duplAppointment={duplAppointment}
          byClick={byClick}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          setLastElement={setLastElement}
          data={getSearchList?.data?.rows}
          isLoading={getSearchList.isFetching}
          setPage={setPage}
        />
      </S.InputRow>
      <S.InputRow>
        <Phone
          formik={formik}
          userProfile={userProfile}
          selectedAppointment={selectedAppointment}
          duplAppointment={duplAppointment}
          byClick={byClick}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          setLastElement={setLastElement}
          data={getSearchList?.data?.rows}
          isLoading={getSearchList.isFetching}
          setPage={setPage}
        />
        <Email
          formik={formik}
          userProfile={userProfile}
          selectedAppointment={selectedAppointment}
          duplAppointment={duplAppointment}
          byClick={byClick}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          setLastElement={setLastElement}
          data={getSearchList?.data?.rows}
          isLoading={getSearchList.isFetching}
          setPage={setPage}
        />
      </S.InputRow>
      {me.role !== Roles.PATIENT && (
        <S.InputRow className="half">
          <DOBV2
            formik={formik}
            userProfile={userProfile}
            selectedAppointment={selectedAppointment}
            duplAppointment={duplAppointment}
            byClick={byClick}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            setLastElement={setLastElement}
            data={getSearchList?.data?.rows}
            isLoading={getSearchList.isFetching}
            setPage={setPage}
            emptyDatePicker={emptyDatePicker}
            setEmptyDatePicker={setEmptyDatePicker}
          />
        </S.InputRow>
      )}
      {me.role !== Roles.PATIENT &&
        (userProfile?.clinics[0]?.accountNumber ||
          selectedAppointment?.patient?.clinics[0]?.ClinicUser?.accountNumber) &&
        !selectedAppointment?.isPossibleToUpdate && (
          <S.AccountNumber>
            Patient account number:{' '}
            {userProfile
              ? userProfile.clinics?.[0]?.accountNumber
              : selectedAppointment?.patient?.clinics?.[0]?.ClinicUser?.accountNumber}
          </S.AccountNumber>
        )}
      {me.role !== Roles.PATIENT &&
        (userProfile?.profile?.insurances?.[0]?.primaryInsuranceCompany ||
          selectedAppointment?.patient?.profile?.insurances?.[0]
            ?.primaryInsuranceCompany) &&
        !selectedAppointment?.isPossibleToUpdate && (
          <S.AccountNumber>
            Primary Insurance:{' '}
            {userProfile
              ? userProfile.profile?.insurances?.filter(
                  insurance => insurance.isPrimary,
                )[0]?.primaryInsuranceCompany
              : selectedAppointment?.patient?.profile?.insurances?.filter(
                  insurance => insurance.isPrimary,
                )[0]?.primaryInsuranceCompany}
          </S.AccountNumber>
        )}
      {(me.role === Roles.PATIENT ||
        (selectedAppointment &&
          selectedAppointment.patientNote &&
          selectedAppointment?.status !== AppointmentStatus.ACTIVE &&
          selectedAppointment?.status !== AppointmentStatus.COMPLETED)) && (
        <S.InputRow>
          <Input
            label="Patient note"
            id="patientNote"
            name="patientNote"
            error={!!formik.errors.patientNote}
            helperText={formik.errors.patientNote as string}
            value={formik.values.patientNote}
            onChange={handleChangeInput}
            disabled={selectedAppointment?.status === AppointmentStatus.CANCELED}
            multiline
            rows={4}
          />
        </S.InputRow>
      )}
    </S.PatientInformation>
  );
};

export default PatientInformationBlock;
