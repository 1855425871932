import { useState } from 'react';
import { CustomPagination, CustomTable } from 'components';
import { useInvoicePostPaymentAllQuery } from 'store/api/invoices/invoiceApi';
import * as S from './styles';
import { useDebounce } from 'use-debounce';
import { useAppSelector } from 'hooks/useAppSelector';
import InvoicePostpaymentRow from './TableData/InvoicePostpaymentRow';

const headers = [
  // { name: 'Year', key: 'Year' },
  { name: 'Clinic name', key: 'Clinic' },
  { name: 'Charged', key: 'Charged' },
  { name: 'Status', key: 'Status' },
  { name: 'Number of items', key: 'Number' },
];

const INVOICE_POST_PAYMENT_LIMIT = 20;

const InvoicePostPayment = () => {
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 500);
  const [page, setPage] = useState(1);
  const userInfo = useAppSelector(state => state.auth);
  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const [filter, setFilter] = useState('');

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPage(1);
    setSearchValue(e.target.value);
  };

  const getList = useInvoicePostPaymentAllQuery({
    q: debouncedSearchValue || undefined,
    page: page,
    take: INVOICE_POST_PAYMENT_LIMIT,
    clinicId: selectedClinic.id,
  });

  return (
    <S.TableBody>
      <S.HeaderTable>
        <CustomPagination
          isFetching={getList.isFetching}
          setPage={setPage}
          currentPage={getList.currentData?.page}
          lastPage={getList.currentData?.lastPage}
          count={getList?.currentData?.count}
          text={'invoices'}
          limit={INVOICE_POST_PAYMENT_LIMIT}
        />
        <S.SubtitleItems>
          {/* <S.SearchWrapper>
            <SearchInput value={searchValue} onChange={handleSearch} />
          </S.SearchWrapper> */}
        </S.SubtitleItems>
      </S.HeaderTable>
      <CustomTable headers={headers} isFetching={getList.isFetching || getList.isLoading}>
        <InvoicePostpaymentRow
          clickable
          data={getList?.currentData?.rows}
          headers={headers}
        />
      </CustomTable>
    </S.TableBody>
  );
};

export default InvoicePostPayment;
