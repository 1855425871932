import dayjs from 'dayjs';
import { AppointmentsNew } from 'types/AppointmentNewTypes';
import * as S from './styles';
import { SelectInput } from '../../../../index';
import { IconButton, InputAdornment, MenuItem, SelectChangeEvent } from '@mui/material';
import { AppointmentStatus } from '../../../../../types/StatusTypes';
import { CloseBtnSVG } from '../../../../../assets/icons';
import { AppointmentCancell } from '../../../../../constants/constants';
import React from 'react';
import { FormikProps } from 'formik';
import { AppointmentFormValues } from '../../AppointmentDrawer';

type Props = {
  selectedAppointment?: AppointmentsNew;
  formik: FormikProps<AppointmentFormValues>;
};

const AppointmentHistory = ({ selectedAppointment, formik }: Props) => {
  return (
    <S.Wrapper>
      <S.Line />
      <S.WrapperHistory>
        <S.Side>
          <S.Text>Created by: {selectedAppointment?.createdBy ?? '-'}</S.Text>
          <S.Text>
            Time created:{' '}
            {dayjs(selectedAppointment?.createdAt).format('h:mm A, MM/DD/YYYY') ?? '-'}
          </S.Text>
        </S.Side>
        <S.Side>
          <S.Text>Last Edited by: {selectedAppointment?.updatedBy ?? '-'} </S.Text>
          <S.Text>
            Time edited:{' '}
            {dayjs(selectedAppointment?.updatedAt).format('h:mm A, MM/DD/YYYY') ?? '-'}{' '}
          </S.Text>
        </S.Side>
      </S.WrapperHistory>

      {selectedAppointment?.billingWhoChecked?.profile?.firstName && (
        <S.WrapperHistoryBilling>
          <S.Side>
            <S.Text>
              Checked by:{' '}
              {selectedAppointment?.billingWhoChecked?.profile?.firstName
                ? selectedAppointment.billingWhoChecked.profile.firstName +
                  ' ' +
                  selectedAppointment.billingWhoChecked.profile.lastName
                : '-'}{' '}
            </S.Text>
            <S.Text>
              Time checked:{' '}
              {dayjs(selectedAppointment?.checkedDate).format('h:mm A, MM/DD/YYYY') ??
                '-'}{' '}
            </S.Text>
          </S.Side>
        </S.WrapperHistoryBilling>
      )}
      {formik.values.status === AppointmentStatus.CANCELED ? (
        <S.SelectContent>
          <S.TextCancel>Canceled reason</S.TextCancel>
          <S.SelectInputWrap>
            <SelectInput
              label=""
              disabled={
                selectedAppointment?.status === AppointmentStatus.COMPLETED ||
                selectedAppointment?.status === AppointmentStatus.CANCELED
              }
              id="reason"
              name="reason"
              error={!!formik.errors.reason}
              helperText={formik.errors.reason}
              value={formik.values.reason || ''}
              onChange={(e: SelectChangeEvent<unknown>) => {
                const newValue = e.target.value;
                formik.setFieldError('reason', '');
                formik.setFieldValue('reason', newValue);
              }}
              endAdornment={
                !!formik.values.reason &&
                !(selectedAppointment?.status === AppointmentStatus.CANCELED) && (
                  <InputAdornment sx={{ marginRight: '12px' }} position="end">
                    <IconButton
                      onClick={() => {
                        formik.setFieldValue('reason', '');
                      }}
                    >
                      <CloseBtnSVG />
                    </IconButton>
                  </InputAdornment>
                )
              }
            >
              {Object.values(AppointmentCancell).map(value => (
                <MenuItem key={value} value={value}>
                  <S.MenuItemContent>{value}</S.MenuItemContent>
                </MenuItem>
              ))}
            </SelectInput>
          </S.SelectInputWrap>
        </S.SelectContent>
      ) : (
        <S.EmptyContent></S.EmptyContent>
      )}
    </S.Wrapper>
  );
};

export default AppointmentHistory;
