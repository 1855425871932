import React, { useEffect, useState } from 'react';
import { useClinicsGetQuery } from 'store/api/clinics/clinicApi';
import { useDebounce } from 'use-debounce';
import { useGetAllDoctorsQuery } from 'store/api/staff/staffApi';
import { MenuItem, RadioGroup } from '@mui/material';
import { TooltipInput, RadioButton, TooltipSelectInput } from 'components';
import AutoCompleteInput from 'components/atoms/AutoComplete';
import { FormikProps } from 'formik';
import { ProviderInfoFormType } from 'types/AppointmentNewTypes';

import * as S from '../../styles';
import { Facility } from '../../../../../../../../types/FacilityTypes';

type Props = {
  providerFormik: FormikProps<Partial<ProviderInfoFormType>>;
  checkStatusCondition?: boolean;
  isBlankClaim?: boolean;
  clinicId?: string;
  facilities?: Facility[];
};

export const RenderingCard = ({
  providerFormik,
  checkStatusCondition,
  isBlankClaim,
  facilities,
  clinicId,
}: Props) => {
  const clinics = useClinicsGetQuery({});
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 500);
  const getAll = useGetAllDoctorsQuery({
    q: debouncedSearchValue,
    page: 1,
    take: 1000,
    clinicId,
  });

  const [selectedValue, setSelectedValue] = useState<any>(null);

  const onChangeFacility = (facilityId: string) => {
    const currentFacility = facilities?.find(i => i.id === facilityId);

    if (currentFacility) {
      void providerFormik.setValues({
        ...providerFormik.values,
        renderingName: currentFacility?.facilityName ?? '',
        renderingId: currentFacility?.id ?? '',
        renderingNpi: currentFacility?.npi ?? '',
      });
    }
  };

  const setInitValue = () => {
    setSelectedValue(null);
    void providerFormik.setValues({
      ...providerFormik.values,
      renderingName: '',
      renderingId: '',
      renderingNpi: '',
    });
  };

  useEffect(() => {
    if (selectedValue) {
      void providerFormik.setValues({
        ...providerFormik.values,
        renderingName: selectedValue?.user?.profile
          ? selectedValue?.user?.profile?.firstName +
            ' ' +
            selectedValue?.user?.profile?.lastName
          : '',
        renderingId: selectedValue?.userId ? selectedValue?.userId : '',
        renderingNpi:
          providerFormik.values?.renderingId === selectedValue?.userId
            ? providerFormik.values?.renderingNpi
            : (selectedValue?.user?.credentials?.npi ?? ''),
      });
    }
  }, [selectedValue]);

  return (
    <S.Card>
      <S.EmptyDiv>
        <S.Caption>31. RENDERING PROVIDER</S.Caption>
        <RadioGroup
          value={providerFormik.values.isFhysicanRendering}
          onChange={e => {
            setInitValue();
            providerFormik.setFieldValue('isFhysicanRendering', e.target.value);
          }}
        >
          <S.Label>
            <S.LabelArticle />
          </S.Label>
          <S.RadioItem>
            <S.RadioButtons>
              <RadioButton
                label="Facility"
                value={'isFacilityRendering'}
                disabled={checkStatusCondition}
              />
              <RadioButton
                label="Physician"
                value={'isFhysicanRendering'}
                disabled={checkStatusCondition}
              />
            </S.RadioButtons>
          </S.RadioItem>
        </RadioGroup>
        <S.InputWrapper className="all">
          {providerFormik.values.isFhysicanRendering === 'isFhysicanRendering' ? (
            <>
              {isBlankClaim ? (
                <S.InputAutoCompleteWrapper>
                  <TooltipInput
                    label="Name"
                    disabled={checkStatusCondition}
                    {...providerFormik.getFieldProps('renderingName')}
                    error={!!providerFormik.errors.renderingName}
                    tooltipHelperText={providerFormik.errors.renderingName}
                  />
                </S.InputAutoCompleteWrapper>
              ) : (
                <S.InputAutoCompleteWrapper>
                  <AutoCompleteInput
                    data={getAll?.currentData?.rows || []}
                    mainLabel={'Name'}
                    loading={!!getAll.isLoading}
                    searchValue={searchValue}
                    disabled={checkStatusCondition}
                    setSearchValue={setSearchValue}
                    selectedValue={selectedValue}
                    setSelectedValue={setSelectedValue}
                    defaultValue={providerFormik.values?.renderingId ?? undefined}
                    error={!!providerFormik.errors.renderingName}
                    helperText={providerFormik.errors.renderingName}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option?.userId === value?.userId
                    }
                  />
                </S.InputAutoCompleteWrapper>
              )}
            </>
          ) : (
            <S.EmptyDiv className={'smallGap'}>
              {isBlankClaim ? (
                <TooltipInput
                  label="Facility Name"
                  disabled={checkStatusCondition}
                  {...providerFormik.getFieldProps('renderingName')}
                  error={!!providerFormik.errors.renderingName}
                  tooltipHelperText={providerFormik.errors.renderingName}
                />
              ) : (
                <TooltipSelectInput
                  label="Facility Name"
                  disabled={checkStatusCondition}
                  value={providerFormik.values.renderingId}
                  onChange={e => onChangeFacility(e.target.value as string)}
                  error={!!providerFormik.errors.renderingName}
                  tooltipHelperText={providerFormik.errors.renderingName}
                >
                  {facilities?.map(facility => (
                    <MenuItem key={facility.id} value={facility?.id}>
                      <S.MenuItemContent>{facility.facilityName}</S.MenuItemContent>
                    </MenuItem>
                  ))}
                </TooltipSelectInput>
              )}
            </S.EmptyDiv>
          )}
        </S.InputWrapper>

        <S.Gap>
          <S.Caption>RENDERING PROVIDER ID</S.Caption>
        </S.Gap>
        <S.InputWrapper className="all">
          <TooltipInput
            label="NPI"
            disabled={checkStatusCondition}
            {...providerFormik.getFieldProps('renderingNpi')}
            error={!!providerFormik.errors.renderingNpi}
            tooltipHelperText={providerFormik.errors.renderingNpi}
          />
        </S.InputWrapper>
      </S.EmptyDiv>
    </S.Card>
  );
};
