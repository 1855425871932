import { useEffect, useRef, useState } from 'react';
import { ClaimsWhiteSVG, ClearFiltersSVG } from 'assets/icons';
import {
  Button,
  DatePicker,
  Dialog,
  SearchInput,
  SelectInput,
  TabsItem,
} from 'components';
import { ClaimStatusType, ClaimTypeEnum } from 'types/InvoiceType';
import ClaimColumn from './components/ClaimColumn';
import * as S from './styles';
import { useNavigate } from 'react-router-dom';
import { MenuItem, Tab } from '@mui/material';
import { claimStatusArray } from './components/ClaimColumn/config';
import { useLazyGetAllClaimsQueryQuery } from 'store/api/claims/claimsApi';
import { useAppSelector } from 'hooks/useAppSelector';
import CreateClaimDialog from './components/CreateClaimDialog';
import ConfigCreateNewClaim from './components/CreateClaimDialog/ConfigCreateNewClaim';
import { useDebounce } from 'use-debounce';
import dayjs, { Dayjs } from 'dayjs';
import { searchKeys } from './config';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { setUpdateClaim } from '../../../../store/slices/chatSlice';
import { setTab } from '../../../../store/slices/claimTabSlice';

const Claims = () => {
  const [openCreateClaim, setOpenCreateClaim] = useState(false);
  const navigate = useNavigate();

  const selectedClinic = useAppSelector(state => state.selectedClinic);
  const { claimIsUpdated } = useAppSelector(state => state.chats);
  const { tab } = useAppSelector(state => state.tab);

  const [searchKey, setSearchKey] = useState<string>(searchKeys[0].key);
  const [searchValue, setSearchValue] = useState('');

  const [debouncedSearch] = useDebounce(searchValue, 500);

  const [page, setPage] = useState(1);

  const [getClaimList, status] = useLazyGetAllClaimsQueryQuery();

  const [selectedType, setSelectedType] = useState<ClaimTypeEnum | null>(null);

  const dispatch = useAppDispatch();

  const refetch = () => {
    setPage(1);
    if (!selectedClinic.id) return;
    getClaimList({
      clinicId: selectedClinic.id,
      status: claimStatusArray[tab],
      take: 20,
      ...(debouncedSearch ? { searchKey } : {}),
      page: 1,
      ...(debouncedFirstDate &&
        !isNaN(dayjs(debouncedFirstDate).day()) && {
          startDate: dayjs(debouncedFirstDate).format('YYYY-MM-DD'),
        }),
      ...(debounceSecondDate &&
        !isNaN(dayjs(debounceSecondDate).day()) && {
          endDate: dayjs(debounceSecondDate).format('YYYY-MM-DD'),
        }),
      q: debouncedSearch,
    });
    scrollToTop();
    dispatch(setUpdateClaim(false));
  };

  const [firstDate, setFirstDate] = useState<Dayjs | null | string>('');
  const [secondDate, setSecondDate] = useState<Dayjs | null | string>('');

  const [debouncedFirstDate] = useDebounce(firstDate, 500);
  const [debounceSecondDate] = useDebounce(secondDate, 500);

  useEffect(() => {
    if (!selectedClinic?.id) return;
    getClaimList({
      clinicId: selectedClinic.id,
      status: claimStatusArray[tab],
      take: 20,
      ...(debouncedSearch ? { searchKey } : {}),
      page: page,
      ...(debouncedFirstDate &&
        !isNaN(dayjs(debouncedFirstDate).day()) && {
          startDate: dayjs(debouncedFirstDate).format('YYYY-MM-DD'),
        }),
      ...(debounceSecondDate &&
        !isNaN(dayjs(debounceSecondDate).day()) && {
          endDate: dayjs(debounceSecondDate).format('YYYY-MM-DD'),
        }),
      q: debouncedSearch,
    });
  }, [
    tab,
    page,
    selectedClinic,
    selectedClinic?.id,
    // debouncedFirstDate,
    // debounceSecondDate,
    // debouncedSearch,
  ]);

  useEffect(() => {
    if (page !== 1) {
      refetch();
      scrollToTop();
    }
  }, [selectedClinic, tab, selectedClinic.id]);

  useEffect(() => {
    refetch();
  }, [debounceSecondDate, debouncedFirstDate, debouncedSearch, searchKey]);

  useEffect(() => {
    if (claimIsUpdated) {
      refetch();
    }
  }, [claimIsUpdated]);

  const messageStartRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (secondDate && firstDate && secondDate < firstDate) {
      setFirstDate(secondDate);
      setPage(1);
    }
  }, [secondDate]);

  const scrollToTop = () => {
    messageStartRef.current?.scrollIntoView();
  };

  useEffect(() => {
    if (selectedType === ClaimTypeEnum.BLANK) {
      navigate(`/claim-form`, { state: { claimType: ClaimTypeEnum.BLANK } });
    }
  }, [selectedType]);

  return (
    <S.Wrapper>
      <Dialog open={openCreateClaim} onClose={() => setOpenCreateClaim(!openCreateClaim)}>
        <CreateClaimDialog
          selectedType={selectedType}
          setSelectedType={setSelectedType}
          onClose={() => {
            setOpenCreateClaim(!openCreateClaim);
          }}
        />
      </Dialog>
      {selectedType && (
        <ConfigCreateNewClaim
          refetch={refetch}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
        />
      )}
      <S.SubtitleBar>
        <S.SubtitleText>All Claims</S.SubtitleText>
        <S.SubtitleItems>
          <S.ClearFilter
            onClick={() => {
              setFirstDate('');
              setSecondDate('');
              setSearchValue('');
              setPage(1);
            }}
          >
            <ClearFiltersSVG />
          </S.ClearFilter>

          <S.DatePickerWrapper>
            <DatePicker
              label=""
              shouldDisableDate={date => !!secondDate && date > secondDate}
              value={firstDate}
              onChange={value => {
                setFirstDate(dayjs(value));
                setPage(1);
              }}
              id="birthDate"
              name="birthDate"
            />
          </S.DatePickerWrapper>
          {'-'}
          <S.DatePickerWrapper>
            <DatePicker
              shouldDisableDate={date => !!firstDate && date < firstDate}
              label=""
              value={secondDate}
              onChange={value => {
                setSecondDate(dayjs(value));
                setPage(1);
              }}
              id="birthDate"
              name="birthDate"
            />
          </S.DatePickerWrapper>
          <S.SelectWrapper className="xl">
            <SelectInput
              label=""
              background="#fff"
              value={searchKey}
              onChange={e => {
                setSearchKey(e.target.value as string);
              }}
            >
              {searchKeys.map(item => {
                return (
                  <MenuItem key={item.name} value={item.key}>
                    <S.MenuItemContent>{item.name}</S.MenuItemContent>
                  </MenuItem>
                );
              })}
            </SelectInput>
          </S.SelectWrapper>
          <S.SelectWrapper>
            <SearchInput
              isSearch={searchValue.length > 0}
              label=""
              placeholder="Search"
              value={searchValue}
              onChange={e => {
                setPage(1);
                setSearchValue(e.target.value);
              }}
            />
          </S.SelectWrapper>
          <S.ButtonWrapper onClick={() => setOpenCreateClaim(!openCreateClaim)}>
            <Button text="Create claim">
              <ClaimsWhiteSVG />
            </Button>
          </S.ButtonWrapper>
        </S.SubtitleItems>
      </S.SubtitleBar>
      <TabsItem tabIndex={tab} setTabIndex={() => {}}>
        {claimStatusArray.map((item, index) => (
          <Tab
            key={item}
            disableRipple
            label={item}
            onClick={() => {
              setPage(1);
              dispatch(setTab(index));
            }}
          ></Tab>
        ))}
      </TabsItem>
      <S.Content>
        <ClaimColumn
          isLoading={status.isFetching || status.isLoading}
          status={claimStatusArray[tab] as ClaimStatusType}
          count={status.data?.count}
          lastPage={status.data?.lastPage}
          data={status.data?.rows}
          page={page}
          setPage={setPage}
          refetch={refetch}
          messageStartRef={messageStartRef}
        />
      </S.Content>
    </S.Wrapper>
  );
};

export default Claims;
