import React from 'react';
import * as S from './styles';
import { emptyCoveredService, NewInsuranceGroupsType, Shares } from './config';
import { FormikProps, getIn } from 'formik';
import { RadioButton, TooltipInput, TooltipSelectInput } from 'components';
import {
  IconButton,
  InputAdornment,
  MenuItem,
  RadioGroup,
  SelectChangeEvent,
} from '@mui/material';
import { AddCircleBlueSVG, CloseBtnSVG } from 'assets/icons';
import { CoveredServiceCard } from './CoveredServiceCard';
import { InsuranceFormValues } from 'pages/Users/components/Profile/components/InsuranceInformation_v2/components/AddInsuranceDialog/AddInsuranceDialog';
import { TooltipNumberInput } from '../../../../components/atoms/Input/TooltipNumberInput';

type Props = {
  formik: FormikProps<NewInsuranceGroupsType> | FormikProps<InsuranceFormValues>;
  index: number;
  fromInsurancePage: boolean;
  setIsDirtyForm: (isDirtyForm: boolean) => void;
};

export const GroupNumberDetails = ({
  formik,
  index,
  fromInsurancePage,
  setIsDirtyForm,
}: Props) => {
  const onAddServiceClick = () => {
    setIsDirtyForm(true);
    formik.setFieldValue(
      `groups[${index}].coveredServices`,
      formik.values?.groups?.at(index)?.coveredServices?.concat(emptyCoveredService),
    );
  };

  return (
    <S.GroupNumberCard>
      <S.Line>
        <S.InputWrapper className="px480">
          <TooltipInput
            value={formik.values?.groups?.at(index)?.groupNumber}
            onChange={e => {
              setIsDirtyForm(true);
              formik.setFieldValue(`groups[${index}].groupNumber`, e.target.value);
            }}
            label="Group #"
            error={!!getIn(formik.errors, `groups[${index}].groupNumber`)}
            tooltipHelperText={getIn(formik.errors, `groups[${index}].groupNumber`)}
          />
        </S.InputWrapper>

        <S.InputWrapper className="flex">
          <TooltipInput
            value={formik.values?.groups?.at(index)?.note}
            onChange={e => {
              setIsDirtyForm(true);
              formik.setFieldValue(`groups[${index}].note`, e.target.value);
            }}
            label="Note"
            error={!!getIn(formik.errors, `groups[${index}].note`)}
            tooltipHelperText={getIn(formik.errors, `groups[${index}].note`)}
          />
        </S.InputWrapper>
      </S.Line>

      <S.Line className={'mt13'}>
        <S.ArticleText className={'fs13'}>3. What is the deductible?</S.ArticleText>
      </S.Line>

      <S.Line className={'m7'}>
        <S.ArticleText className={'bold'}>In-Net</S.ArticleText>
        <S.ArticleText className={'bold'}>Out-Net</S.ArticleText>
      </S.Line>
      <S.Line className={'m7'}>
        <S.ArticleText className={'normal'}>Met</S.ArticleText>
        <S.InputWrapper className="flex">
          <TooltipNumberInput
            type="number"
            isSum="$"
            value={formik.values?.groups?.at(index)?.inNetMet}
            onChange={e => {
              setIsDirtyForm(true);
              formik.setFieldValue(`groups[${index}].inNetMet`, e.target.value);
            }}
            error={!!getIn(formik.errors, `groups[${index}].inNetMet`)}
            tooltipHelperText={getIn(formik.errors, `groups[${index}].inNetMet`)}
          />
        </S.InputWrapper>

        <S.ArticleText className={'normal'}>Total</S.ArticleText>
        <S.InputWrapper className="flex">
          <TooltipNumberInput
            type="number"
            isSum="$"
            value={formik.values?.groups?.at(index)?.inNetTotal}
            onChange={e => {
              setIsDirtyForm(true);
              formik.setFieldValue(`groups[${index}].inNetTotal`, e.target.value);
            }}
            error={!!getIn(formik.errors, `groups[${index}].inNetTotal`)}
            tooltipHelperText={getIn(formik.errors, `groups[${index}].inNetTotal`)}
          />
        </S.InputWrapper>

        <S.ArticleText className={'normal'}>Met</S.ArticleText>
        <S.InputWrapper className="flex">
          <TooltipNumberInput
            type="number"
            isSum="$"
            value={formik.values?.groups?.at(index)?.inOutMet}
            onChange={e => {
              setIsDirtyForm(true);
              formik.setFieldValue(`groups[${index}].inOutMet`, e.target.value);
            }}
            error={!!getIn(formik.errors, `groups[${index}].inOutMet`)}
            tooltipHelperText={getIn(formik.errors, `groups[${index}].inOutMet`)}
          />
        </S.InputWrapper>

        <S.ArticleText className={'normal'}>Total</S.ArticleText>
        <S.InputWrapper className="flex">
          <TooltipNumberInput
            type="number"
            isSum="$"
            value={formik.values?.groups?.at(index)?.inOutTotal}
            onChange={e => {
              setIsDirtyForm(true);
              formik.setFieldValue(`groups[${index}].inOutTotal`, e.target.value);
            }}
            error={!!getIn(formik.errors, `groups[${index}].inOutTotal`)}
            tooltipHelperText={getIn(formik.errors, `groups[${index}].inOutTotal`)}
          />
        </S.InputWrapper>
      </S.Line>

      <S.Line className={'m13'}>
        <S.ArticleText className={'fs13'}>What is out of pocket limit?</S.ArticleText>
      </S.Line>

      <S.Line className={'m13'}>
        <S.ArticleText className={'normal'}>In</S.ArticleText>
        <S.InputWrapper className="flex">
          <TooltipNumberInput
            type="number"
            isSum="$"
            value={formik.values?.groups?.at(index)?.pocketLimitInFrom}
            onChange={e => {
              setIsDirtyForm(true);
              formik.setFieldValue(`groups[${index}].pocketLimitInFrom`, e.target.value);
            }}
            error={!!getIn(formik.errors, `groups[${index}].pocketLimitInFrom`)}
            tooltipHelperText={getIn(formik.errors, `groups[${index}].pocketLimitInFrom`)}
          />
        </S.InputWrapper>

        <S.ArticleText className={'normal'}>-</S.ArticleText>
        <S.InputWrapper className="flex">
          <TooltipNumberInput
            type="number"
            isSum="$"
            value={formik.values?.groups?.at(index)?.pocketLimitInTo}
            onChange={e => {
              setIsDirtyForm(true);
              formik.setFieldValue(`groups[${index}].pocketLimitInTo`, e.target.value);
            }}
            error={!!getIn(formik.errors, `groups[${index}].pocketLimitInTo`)}
            tooltipHelperText={getIn(formik.errors, `groups[${index}].pocketLimitInTo`)}
          />
        </S.InputWrapper>

        <S.ArticleText className={'normal'}>Out</S.ArticleText>
        <S.InputWrapper className="flex">
          <TooltipNumberInput
            type="number"
            isSum="$"
            value={formik.values?.groups?.at(index)?.pocketLimitOutFrom}
            onChange={e => {
              setIsDirtyForm(true);
              formik.setFieldValue(`groups[${index}].pocketLimitOutFrom`, e.target.value);
            }}
            error={!!getIn(formik.errors, `groups[${index}].pocketLimitOutFrom`)}
            tooltipHelperText={getIn(
              formik.errors,
              `groups[${index}].pocketLimitOutFrom`,
            )}
          />
        </S.InputWrapper>

        <S.ArticleText className={'normal'}>-</S.ArticleText>
        <S.InputWrapper className="flex">
          <TooltipNumberInput
            type="number"
            isSum="$"
            value={formik.values?.groups?.at(index)?.pocketLimitOutTo}
            onChange={e => {
              setIsDirtyForm(true);
              formik.setFieldValue(`groups[${index}].pocketLimitOutTo`, e.target.value);
            }}
            error={!!getIn(formik.errors, `groups[${index}].pocketLimitOutTo`)}
            tooltipHelperText={getIn(formik.errors, `groups[${index}].pocketLimitOutTo`)}
          />
        </S.InputWrapper>
      </S.Line>

      <S.Line className={'mt'}>
        <S.ArticleText className={'fs13'}>
          4. What is the co/insurance on policy?
        </S.ArticleText>

        <S.EmptyDiv width={'40px'} />
        <S.ArticleText className={'normal'}>In-Network:</S.ArticleText>
        <S.InputWrapper className="px180">
          <TooltipSelectInput
            displayEmpty
            label=""
            value={formik.values?.groups?.at(index)?.insuranceOnPolicyIn ?? ''}
            onChange={(e: SelectChangeEvent<unknown>) => {
              setIsDirtyForm(true);
              formik.setFieldValue(
                `groups[${index}].insuranceOnPolicyIn`,
                e.target.value,
              );
            }}
            endAdornment={
              !!formik.values?.groups?.at(index)?.insuranceOnPolicyIn && (
                <InputAdornment sx={{ marginRight: '12px' }} position="end">
                  <IconButton
                    onClick={() => {
                      setIsDirtyForm(true);
                      formik.setFieldValue(`groups[${index}].insuranceOnPolicyIn`, '');
                    }}
                  >
                    <CloseBtnSVG />
                  </IconButton>
                </InputAdornment>
              )
            }
          >
            {Shares.map(item => {
              return (
                <MenuItem value={item.value} key={item.value}>
                  <S.MenuItemContent>{item.name}</S.MenuItemContent>
                </MenuItem>
              );
            })}
          </TooltipSelectInput>
        </S.InputWrapper>

        <S.ArticleText className={'normal'}>Out-Network:</S.ArticleText>
        <S.InputWrapper className="px180">
          <TooltipSelectInput
            displayEmpty
            label=""
            value={formik.values?.groups?.at(index)?.insuranceOnPolicyOut}
            onChange={(e: SelectChangeEvent<unknown>) => {
              setIsDirtyForm(true);
              formik.setFieldValue(
                `groups[${index}].insuranceOnPolicyOut`,
                e.target.value,
              );
            }}
            endAdornment={
              !!formik.values?.groups?.at(index)?.insuranceOnPolicyOut && (
                <InputAdornment sx={{ marginRight: '12px' }} position="end">
                  <IconButton
                    onClick={() => {
                      setIsDirtyForm(true);
                      formik.setFieldValue(`groups[${index}].insuranceOnPolicyOut`, '');
                    }}
                  >
                    <CloseBtnSVG />
                  </IconButton>
                </InputAdornment>
              )
            }
          >
            {Shares.map(item => {
              return (
                <MenuItem value={item.value} key={item.value}>
                  <S.MenuItemContent>{item.name}</S.MenuItemContent>
                </MenuItem>
              );
            })}
          </TooltipSelectInput>
        </S.InputWrapper>
      </S.Line>

      <S.Line className={'m13'}>
        <S.ArticleText className={'fs13'}>Does the patient have a copay?</S.ArticleText>

        <S.EmptyDiv width={'62px'} />
        <S.ArticleText className={'normal'}>In</S.ArticleText>
        <S.InputWrapper className="px240">
          <TooltipNumberInput
            type="number"
            isSum="$"
            value={formik.values?.groups?.at(index)?.copayIn}
            onChange={e => {
              setIsDirtyForm(true);
              formik.setFieldValue(`groups[${index}].copayIn`, e.target.value);
            }}
            error={!!getIn(formik.errors, `groups[${index}].copayIn`)}
            tooltipHelperText={getIn(formik.errors, `groups[${index}].copayIn`)}
          />
        </S.InputWrapper>

        <S.ArticleText className={'normal'}>Out</S.ArticleText>
        <S.InputWrapper className="px240">
          <TooltipNumberInput
            type="number"
            isSum="$"
            value={formik.values?.groups?.at(index)?.copayOut}
            onChange={e => {
              setIsDirtyForm(true);
              formik.setFieldValue(`groups[${index}].copayOut`, e.target.value);
            }}
            error={!!getIn(formik.errors, `groups[${index}].copayOut`)}
            tooltipHelperText={getIn(formik.errors, `groups[${index}].copayOut`)}
          />
        </S.InputWrapper>
      </S.Line>

      <S.Line className={'mt13'}>
        <S.ArticleText className={'fs13'}>5. Is referral required?</S.ArticleText>

        <S.EmptyDiv width={'150px'} />
        <RadioGroup
          value={formik.values?.groups?.at(index)?.isReferallRequired}
          onChange={e => {
            setIsDirtyForm(true);
            formik.setFieldValue(
              `groups[${index}].isReferallRequired`,
              e.target.value === 'true',
            );
          }}
        >
          <S.RadioItem>
            <S.RadioButtons>
              <RadioButton label="Yes" value={true} rightPos />
              <RadioButton label="No" value={false} rightPos />
            </S.RadioButtons>
          </S.RadioItem>
        </RadioGroup>
      </S.Line>

      <S.Line className={'mt13'}>
        <S.ArticleText className={'fs13'}>
          6. Is prior authorization required?
        </S.ArticleText>

        <S.EmptyDiv width={'82px'} />
        <RadioGroup
          value={formik.values?.groups?.at(index)?.isAuthorizationRequired}
          onChange={e => {
            setIsDirtyForm(true);
            formik.setFieldValue(
              `groups[${index}].isAuthorizationRequired`,
              e.target.value === 'true',
            );
          }}
        >
          <S.RadioItem>
            <S.RadioButtons>
              <RadioButton label="Yes" value={true} rightPos />
              <RadioButton label="No" value={false} rightPos />
            </S.RadioButtons>
          </S.RadioItem>
        </RadioGroup>
      </S.Line>

      <S.Line className={'padding'}>
        <S.ArticleText>Covered Services</S.ArticleText>
        <S.SearchBtn onClick={onAddServiceClick} className={'withoutMargin'}>
          <AddCircleBlueSVG />
          Add Service
        </S.SearchBtn>
      </S.Line>

      {formik.values?.groups?.at(index)?.coveredServices?.map((service, serviceIndex) => {
        return (
          <CoveredServiceCard
            formik={formik}
            serviceIndex={serviceIndex}
            groupIndex={index}
            key={index + serviceIndex}
            fromInsurancePage={fromInsurancePage}
            setIsDirtyForm={setIsDirtyForm}
          />
        );
      })}
    </S.GroupNumberCard>
  );
};
