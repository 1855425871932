import React, { useEffect, useState } from 'react';
import { useLazyGetCptWithGroupsQuery } from 'store/api/claims/claimsApi';
import * as S from './styles';
import { CustomTable } from 'components';
import Button from 'components/atoms/Button';
import { Checkbox, TableCell, TableRow } from '@mui/material';
import { boolean } from 'yup';

const headers = [
  { name: 'Code', key: 'code' },
  { name: 'Description', key: 'description' },
];

type Props = {
  onApplyClick: (codes: (string | null)[]) => void;
  currentValue?: (string | null)[];
  title?: string;
};

export const ChooseCptCodeMultipleWithGroups = ({
  onApplyClick,
  currentValue = [],
  title = 'Choose HCPCS/CPT Code',
}: Props) => {
  const [getData, statusCpt] = useLazyGetCptWithGroupsQuery({});
  const [selectedItems, setSelectedItems] = useState<(string | null)[]>(
    currentValue?.filter(
      code => !Array.isArray(code) && Boolean(code) && code?.trim() !== '',
    ),
  );

  useEffect(() => {
    getData({});
  }, [getData]);

  useEffect(() => {
    if (currentValue?.length && statusCpt?.data) {
      setSelectedItems(
        currentValue.filter(
          code => !Array.isArray(code) && Boolean(code) && code?.trim() !== '',
        ),
      );
    }
  }, [currentValue, statusCpt?.data]);

  const toggleSelection = (code: string) => {
    setSelectedItems(prev =>
      prev.includes(code)
        ? prev.filter(item => item !== code && !Array.isArray(item))
        : [...prev, code].filter(
            item => !Array.isArray(item) && Boolean(item) && item?.trim() !== '',
          ),
    );
  };

  return (
    <S.Content>
      <S.Article>{title}</S.Article>
      <S.Table className={'all'}>
        <CustomTable backgroundColor={'#F9F9FC'}>
          {!!statusCpt.data?.length &&
            statusCpt.data.map(group => (
              <React.Fragment key={group?.serviceName}>
                <TableRow
                  sx={{
                    '& .MuiTableCell-root': {
                      border: 'none',
                      textAlign: 'start',
                    },
                  }}
                >
                  <TableCell>
                    <S.Text className={'ml'}>
                      {group?.serviceName === 'otherCodes'
                        ? 'Codes without group'
                        : group?.serviceName}
                    </S.Text>
                  </TableCell>
                </TableRow>
                {group?.codes?.map(cpt => (
                  <TableRow
                    key={cpt?.id}
                    sx={{
                      '& .MuiTableCell-root': {
                        background: selectedItems.includes(cpt?.code) ? '#e7e8ec' : '',
                        border: 'none',
                        width: '100%',
                        textAlign: 'start',
                        display: 'flex',
                        alignItems: 'center',
                        paddingBottom: '15px',
                      },
                    }}
                    onClick={() => toggleSelection(cpt?.code)}
                  >
                    <TableCell>
                      <Checkbox
                        onClick={e => {
                          toggleSelection(cpt?.code);
                          e.stopPropagation();
                        }}
                        checked={selectedItems.includes(cpt?.code)}
                      />
                      <S.CodeDescContainer>
                        <S.Text>{cpt?.code}</S.Text>
                        <S.Text>{cpt?.desc}</S.Text>
                      </S.CodeDescContainer>
                    </TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
        </CustomTable>
      </S.Table>

      <S.ButtonWrapper>
        <Button
          text="Apply"
          onClick={() =>
            onApplyClick(
              selectedItems.filter(
                code => !Array.isArray(code) && Boolean(code) && code?.trim() !== '',
              ),
            )
          }
        />
      </S.ButtonWrapper>
    </S.Content>
  );
};
